import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { apiRefresh } from "../services/api";
import * as types from "../store/Constants";
import jwt_decode from "jwt-decode";
import {
  Row,
  Col,
  DatePicker,
  Form,
  Button,
  Spin,
  Select,
  Card,
  Tabs,
  message,
  Modal,
  Table,
  Tooltip,
} from "antd";
import moment from "moment-timezone";
import { DashboardOutlined, CloseCircleOutlined } from "@ant-design/icons";
import download from "../images/download.png";
import "./DashboardBiStyle.css";
import TodaysTask from "./Components/DailyTaskToDo";
import UpdateModal from "../MyDailyTasksReminders/SideArea/UpdateModal";
import UpdateDateModal from "../MyDailyTasksReminders/SideArea/UpdateDateModal";
import ComfirmToDelete from "../MyDailyTasksReminders/SideArea/ComfirmToDelete";
import AddFollowerModal from "../MyDailyTasksReminders/SideArea/AddFollowerModal";
import Reminders from "./Components/Reminders";
import Signature from "./Components/Signatures";
import Questionnaire from "./Components/Questionnaire";
import Checklist from "./Components/Checklist";
import UpdateReminderModal from "../MyDailyTasksReminders/SideArea/UpdateReminderModal";
import { getAccountsAnalytics } from "../store/Actions";
import { getBranchId } from "../Components/getBranchId";
// import Reminders from "../MyDailyTasksReminders/SideArea/SideArea";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const { TabPane } = Tabs;
var digitialCount = 0;
var OtherCount = 0;
class DashboardBi extends React.Component {
  clientAnalyticRef = React.createRef();
  accountAnalyticRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      admintoken: localStorage.getItem("admintoken"),
      clientAnalytics: {
        categories: [],
        series: [],
        total: 0,
        clients: [],
        isClientPanelOpen: false,
      },
      clientAnalyticsFilter: {
        dateFrom: moment().subtract(1, "months"),
        dateTo: moment(),
        processingPerson: "",
      },
      branchId: localStorage.getItem("selectedBranchId"),
      dateFrom: "2020-07-11T07:26:01.518Z",
      dateTo: new Date().toISOString(),
      pageLoading: false,
      ProcessingPersons: [],
      taskOptions: {
        pendingCounts: 0,
        todaysCounts: 0,
        reminderCounts: 0,
        questionnareCounts: 0,
        digitalCounts: 0,
        checklistCounts: 0,
      },
      accountAnalytics: {
        incoming: 0,
        outgoing: 0,
        isAccountPanelOpen: false,
        clients: [],
      },
      accountAnalyticsFilter: {
        dateFrom: moment().subtract(1, "months"),
        dateTo: moment(),
      },
      visaAnalytics: {
        visa: [],
        count: [],
        branchVisaTypeIds: [],
        clients: [],
        isVisaPanelOpen: false,
        visaName: "",
      },
      visaAnalyticsFilter: {
        processingPerson:
          localStorage.getItem("userOwner") === "True"
            ? "00000000-0000-0000-0000-000000000000"
            : localStorage.getItem("userManager") === "true"
            ? "00000000-0000-0000-0000-000000000000"
            : localStorage.getItem("userOwner") !== "True" &&
              localStorage.getItem("userManager") !== "true" &&
              JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(localStorage.getItem("userSystemPermissions"))
                  )
                )
              ).find((x) => x.role.toLowerCase() === "client database")
                .status === 1
            ? "00000000-0000-0000-0000-000000000000"
            : localStorage.getItem("userId"),
        dateFrom: moment().subtract(1, "months"),
        dateTo: moment(),
      },
      activeTab: "1",
      idleSince: {
        categories: [],
        data: [],
      },
      activeSince: {
        categories: [],
        data: [],
      },
      visaStatusAnalytics: {
        selectedStatus: "",
        data: [],
      },
      visaStatus: [],
      activeBalanceAnalytics: { labels: [], series: [], total: 0 },
      clients: [],
      taskSection: {
        isTaskPanel: false,
        tasks: [],
        todaysTask: [],
        pendingTask: [],
      },
      modalConfig: {
        modalType: "",
        isModalOpen: false,
        modalTitle: "",
        updateData: null,
        users: [],
      },
      reminderSection: {
        isReminderPanel: false,
        reminders: [],
      },
      signatureSection: { isSignaturePanel: false, Signatures: [] },
      questionnaireSection: { isQuestionnairePanel: false, Questionnaires: [] },
      checklistSection: { isChecklistPanel: false, Checklists: [] },
    };
  }
  visaClientColumns = [
    {
      title: "Existing Internal Id",
      dataIndex: "clientSerial",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      render: (data, { id, clientId, memberType, familyId }) => {
        return {
          children: <p style={{ cursor: "pointer" }}>{data}</p>,
          props: {
            onClick: () =>
              this.openClientFile(id, clientId, memberType, familyId),
            className: "pointer",
          },
        };
      },
    },
    {
      title: "Last Updated",
      dataIndex: "lastDate",
      render: (date) => {
        let timezone = moment.tz.guess();
        return moment(date)
          .tz(timezone)
          .format("DD/MM/YYYY");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  clientColumns = [
    {
      title: "Existing Internal Id",
      dataIndex: "clientSerial",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      render: (data, { id, clientId, memberType, familyId }) => {
        return {
          children: <p style={{ cursor: "pointer" }}>{data}</p>,
          props: {
            onClick: () =>
              this.openClientFile(id, clientId, memberType, familyId),
            className: "pointer",
          },
        };
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (date) => {
        let timezone = moment.tz.guess();
        return moment(date)
          .tz(timezone)
          .format("DD/MM/YYYY");
      },
    },
    {
      title: "Worth",
      dataIndex: "worth",
    },
  ];
  accountClientsColumns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => {
        let timezone = moment.tz.guess();
        return moment(date)
          .tz(timezone)
          .format("DD/MM/YYYY");
      },
    },
    {
      title: "Ezm Id",
      dataIndex: "ezmId",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      render: (data, { id, clientId, memberType, familyId }) => {
        return {
          children: <p style={{ cursor: "pointer" }}>{data}</p>,
          props: {
            onClick: () =>
              this.openClientFile(id, clientId, memberType, familyId),
            className: "pointer",
          },
        };
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoiceNo",
    },
  ];

  componentWillMount = () => {
    console.log("DashboardBi will mount");
  };

  componentDidMount = () => {
    let {
      onGetProcessingPersons,
      onGetIdleSinceAnalytics,
      onGetActiveSinceAnalytics,
      onGetVisaStatuses,
      onGetActiveBalanceAnalytics,
      onGetDailyTasks,
      onGetReminder,
    } = this.props;
    this.setState({ pageLoading: true });

    // this.getTaskAnalytics();
    Promise.all([getBranchId()]).then(
      (res) => {
        if (res && res.length > 0) {
          // all loaded
          this.getDigitalCount(res[0]);
          this.getQuestionnaireCount(res[0]);
          this.getClientAnalytics(res[0]);
          this.getAccountAnalytics();
          this.getVisaAnalytics(res[0]);
          onGetIdleSinceAnalytics();
          onGetActiveSinceAnalytics();
          onGetVisaStatuses();
          onGetActiveBalanceAnalytics();
          onGetProcessingPersons();
          // onGetDailyTasks();
          onGetReminder();
        }
        // setQuestionAdded(true);
      },
      (err) => {
        // one or more failed
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    let {
      activeBalanceAnalytics,
      taskSection,
      modalConfig,
      taskOptions,
      clientAnalytics,
      visaStatusAnalytics,
      visaAnalytics,
      reminderSection,
    } = this.state;
    // let timezone = moment.tz.guess();
    let currentDate = moment(new Date()).format("l");
    let clientAnalyticsRes = nextProps && nextProps.clientAnalytics;
    let clientAnalyticsError = nextProps && nextProps.clientAnalyticsError;
    let processingPersons =
      nextProps &&
      nextProps.processingPersons &&
      nextProps.processingPersons.users;
    // let taskAnalytics = nextProps && nextProps.taskAnalytics;
    let accountAnalytics = nextProps && nextProps.accountAnalytics;
    let accountAnalyticsError = nextProps && nextProps.accountAnalyticsError;
    let visaAnalyticsData = nextProps && nextProps.visaAnalytics;
    let visaAnalyticsError = nextProps && nextProps.visaAnalyticsError;
    let idleSinceAnalytics = nextProps && nextProps.idleSinceAnalytics;
    let activeSinceAnalytics = nextProps && nextProps.activeSinceAnalytics;
    let visaStatusAnalytic = nextProps && nextProps.visaStatusAnalytics;
    let visaStatus =
      nextProps && nextProps.visaStatus && nextProps.visaStatus.items;
    let activeAccountBalanceAnalytics = nextProps.activeBalanceAnalytics;
    let dailyTaskRes =
      nextProps && nextProps.dailyTaskRes && nextProps.dailyTaskRes.items;
    let clientDetailsError = nextProps && nextProps.clientDetailsError;
    let clientDetails = nextProps && nextProps.clientDetails;
    let visaClientDetails = nextProps && nextProps.visaClientDetails;
    let visaClientDetailsError = nextProps && nextProps.visaClientDetailsError;
    let reminderTaskRes =
      nextProps && nextProps.reminderTaskRes && nextProps.reminderTaskRes.items;
    if (clientAnalyticsRes) this.filterClientAnalytics(clientAnalyticsRes);
    if (clientAnalyticsError) this.setState({ pageLoading: false });
    if (processingPersons)
      this.setState({
        ProcessingPersons: processingPersons,
        modalConfig: {
          ...modalConfig,
          users: processingPersons.map((el) => ({
            label: el.fullName,
            value: el.id,
          })),
        },
      });
    // if (taskAnalytics) this.filterTaskAnalytics(taskAnalytics);
    if (accountAnalytics)
      this.setState({
        accountAnalytics: {
          incoming: accountAnalytics.incoming,
          outgoing: accountAnalytics.outgoing,
        },
        pageLoading: false,
      });
    if (accountAnalyticsError) this.setState({ pageLoading: false });
    if (visaAnalyticsData) this.filterVisaAnalytics(visaAnalyticsData);
    if (visaAnalyticsError) this.setState({ pageLoading: false });
    if (idleSinceAnalytics)
      this.setState({
        idleSince: {
          categories: idleSinceAnalytics.map((el) => el.client),
          data: idleSinceAnalytics.map((el) => el.days),
        },
      });
    if (activeSinceAnalytics)
      this.setState({
        activeSince: {
          categories: activeSinceAnalytics.map((el) => el.client),
          data: activeSinceAnalytics.map((el) => el.days),
        },
      });
    if (visaStatusAnalytic)
      this.setState({
        visaStatusAnalytics: {
          ...visaStatusAnalytics,
          data: visaStatusAnalytic,
        },
        pageLoading: false,
      });
    if (visaStatus) this.setState({ visaStatus: visaStatus });
    if (activeAccountBalanceAnalytics)
      this.setState({
        activeBalanceAnalytics: {
          ...activeBalanceAnalytics,
          labels: activeAccountBalanceAnalytics.map((el) => el.status),
          series: activeAccountBalanceAnalytics.map((el) => el.amount),
          total: activeAccountBalanceAnalytics
            .map((el) => el.amount)
            .reduce((a, b) => a + b, 0),
        },
      });
    if (dailyTaskRes && !taskSection.tasks.length) {
      let todaysData = dailyTaskRes.filter(
        (el) =>
          moment(currentDate).isSame(moment(el.taskDate).format("l")) &&
          !el.isCompleted
      );
      let pendingData = dailyTaskRes.filter(
        (el) => moment(currentDate) > moment(el.taskDate) && !el.isCompleted
      );
      this.setState({
        taskSection: { ...taskSection, tasks: dailyTaskRes },
        taskOptions: {
          ...taskOptions,
          todaysCounts: todaysData.length,
          pendingCounts: pendingData.length,
        },
      });
    }
    if (clientDetailsError) this.setState({ pageLoading: false });
    if (clientDetails && !clientAnalytics.clients?.length) {
      this.setState({
        clientAnalytics: {
          ...clientAnalytics,
          clients: clientDetails,
          isClientPanelOpen: true,
        },
        pageLoading: false,
      });
    }

    if (visaClientDetails)
      this.setState({
        visaAnalytics: {
          ...visaAnalytics,
          clients: visaClientDetails,
          isVisaPanelOpen: true,
        },
        pageLoading: false,
      });
    if (visaClientDetailsError) this.setState({ pageLoading: false });
    if (reminderTaskRes)
      this.setState({
        reminderSection: {
          ...reminderSection,
          reminders: reminderTaskRes.filter((el) => !el.isCompleted),
        },
      });
  };
  getClientAnalytics = () => {
    let { onGetClientAnalytics } = this.props;
    let { clientAnalyticsFilter } = this.state;
    onGetClientAnalytics({
      processingPerson:
        clientAnalyticsFilter.processingPerson ||
        "00000000-0000-0000-0000-000000000000",
      dateFrom: clientAnalyticsFilter.dateFrom.toISOString(),
      dateTo: clientAnalyticsFilter.dateTo.toISOString(),
    });
  };
  filterClientAnalytics = (clientAnalyticsRes) => {
    let days = clientAnalyticsRes.map((el) =>
      moment(el.day).format("DD/MM/YYYY")
    );
    let clients = clientAnalyticsRes.map((el) => parseInt(el.clients));
    let { clientAnalytics } = this.state;
    this.setState({
      clientAnalytics: {
        ...clientAnalytics,
        categories: days,
        series: clients,
        total: clients.reduce((a, b) => a + b, 0),
        isClientPanelOpen: false,
      },
      pageLoading: false,
    });
  };
  onGetClients = (config) => {
    let { clientAnalytics, clientAnalyticsFilter } = this.state;
    let selectedDate = config.w.globals.labels[config.dataPointIndex];
    let format = moment.utc(selectedDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    let date = moment.utc(format).format();
    this.setState({
      pageLoading: true,
      clientAnalytics: {
        ...clientAnalytics,
        clients: [],
        addedOn: selectedDate,
      },
    });
    this.props.onGetClientsByClientDate({
      processingPerson:
        clientAnalyticsFilter.processingPerson ||
        "00000000-0000-0000-0000-000000000000",
      date: date,
    });
  };
  onGetVisaClients = (config) => {
    let { visaAnalytics, visaAnalyticsFilter } = this.state;
    let visaTypeId = visaAnalytics.branchVisaTypeIds[config.dataPointIndex];
    let visaName = config.w.globals.labels[config.dataPointIndex];
    this.setState(
      {
        visaAnalytics: { ...visaAnalytics, visaName: visaName },
        pageLoading: true,
      },
      () =>
        this.props.onGetVisaClients({
          dateFrom: visaAnalyticsFilter.dateFrom.toISOString(),
          dateTo: visaAnalyticsFilter.dateTo.toISOString(),
          branchVisaTypeId: visaTypeId,
        })
    );
  };

  onClientAnalyticsChange = (type, value) => {
    let { clientAnalyticsFilter } = this.state;
    this.setState({
      clientAnalyticsFilter: { ...clientAnalyticsFilter, [type]: value },
    });
  };
  onAccountAnalyticsChange = (type, value) => {
    let { accountAnalyticsFilter } = this.state;
    this.setState({
      accountAnalyticsFilter: { ...accountAnalyticsFilter, [type]: value },
    });
  };
  onVisaAnalyticChange = (type, value) => {
    let { visaAnalyticsFilter } = this.state;
    this.setState({
      visaAnalyticsFilter: { ...visaAnalyticsFilter, [type]: value },
    });
  };
  onFilterClientAnalytics = () => {
    this.setState({ pageLoading: true });
    this.getClientAnalytics();
  };
  clientAnalyticChart = ({
    clientAnalyticsFilter,
    clientAnalytics,
    ProcessingPersons,
  }) => {
    return (
      <div
        style={{
          height: "480px",
          backgroundColor: "white",
          marginTop: 15,
          marginRight: 10,
          padding: 10,
        }}
      >
        <div>
          <span>
            <h4
              style={{
                color: "#144565",
                margin: "0px 5px 10px",
                fontWeight: "600",
                fontSize: 14,
              }}
            >
              Client Analytics (Created/Day)
            </h4>
          </span>
          <span>
            <h4
              style={{
                color: "#26a0fc",
                float: "right",
                marginTop: "-29px",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              Total: {clientAnalytics.total}
            </h4>
          </span>
        </div>
        <div style={{ padding: 10, position: "relative" }}>
          <Form
            ref={this.clientAnalyticRef}
            {...layout}
            name="basic"
            className="d-flex w-100"
            onFinish={() => this.onFilterClientAnalytics()}
          >
            <Form.Item name="pUser">
              <Select
                showSearch
                style={{ width: 120, borderRadius: 0, marginRight: 10 }}
                placeholder="Select User"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(val) =>
                  this.onClientAnalyticsChange("processingPerson", val)
                }
                value={
                  clientAnalyticsFilter.processingPerson
                    ? clientAnalyticsFilter.processingPerson
                    : null
                }
                defaultValue={
                  clientAnalyticsFilter.processingPerson
                    ? clientAnalyticsFilter.processingPerson
                    : null
                }
              >
                <Option value={"00000000-0000-0000-0000-000000000000"}>
                  All
                </Option>
                {ProcessingPersons.map(({ id, fullName }) => (
                  <Option value={id}>{fullName}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="dateTo" labelAlign="left">
              <DatePicker
                className="bi-date"
                placeholder={"Select Date From"}
                calendarIcon={null}
                clearIcon={null}
                onChange={(e) => this.onClientAnalyticsChange("dateFrom", e)}
                format="DD/MM/YYYY"
                defaultValue={clientAnalyticsFilter.dateFrom}
                value={clientAnalyticsFilter.dateFrom}
              />
            </Form.Item>
            <Form.Item>
              <span style={{ marginRight: 5, marginLeft: 5 }}>to</span>
            </Form.Item>
            <Form.Item name="dateFrom" labelAlign="left">
              <DatePicker
                className="bi-date"
                placeholder={"Select Date To"}
                calendarIcon={null}
                clearIcon={null}
                defaultValue={clientAnalyticsFilter.dateTo}
                onChange={(e) => this.onClientAnalyticsChange("dateTo", e)}
                format="DD/MM/YYYY"
                value={clientAnalyticsFilter.dateTo}
              />
            </Form.Item>
            <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Apply
              </Button>
            </Form.Item>
          </Form>
          {/* <Tooltip placement="topLeft" title={`Coming Soon`}>
            <img
              src={download}
              width="20"
              style={{
                position: "absolute",
                right: 0,
                top: 17,
                cursor: "pointer",
              }}
            />
          </Tooltip> */}
        </div>
        <div className="mixed-chart">
          <Chart
            options={{
              chart: {
                id: "basic-bar",
                toolbar: {
                  tools: {
                    download: `<img hidden src=${download} class="client-ico-download" width="20">`,
                  },
                },
                events: {
                  dataPointSelection: (event, chartContext, config) =>
                    this.onGetClients(config),
                },
              },
              xaxis: {
                categories: clientAnalytics.categories,
              },
              fill: {
                colors: ["#edc237"],
              },
            }}
            series={[
              {
                name: "Client",
                data: clientAnalytics.series,
              },
            ]}
            type="bar"
            width="100%"
            height="380"
          />
        </div>
      </div>
    );
  };
  getTaskAnalytics = () => {
    let { onGetTaskAnalytics } = this.props;
    onGetTaskAnalytics();
  };
  getDigitalCount = (id) => {
    let branchId = id;
    let processingPerson =
      localStorage.getItem("userOwner") === "True"
        ? "00000000-0000-0000-0000-000000000000"
        : localStorage.getItem("userManager") === "true"
        ? "00000000-0000-0000-0000-000000000000"
        : localStorage.getItem("userId");
    const docTypeOpt = {
      url: `v1/client/contract/GetCount`,
    };
    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh.get(docTypeOpt).then((res) => {
      digitialCount = res.count;
      this.setState({
        taskOptions: {
          ...this.state.taskOptions,
          digitalCounts: res.count,
        },
      });
    });
  };
  getQuestionnaireCount = () => {
    const docTypeOpt1 = {
      url: `v1/dashboardbi/ChecklistQuestionnaireCount`,
    };

    docTypeOpt1.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh
      .get(docTypeOpt1)
      .then((res1) => {
        this.setState({
          taskOptions: {
            ...this.state.taskOptions,
            questionnareCounts: res1.questionnaire,
            checklistCounts: res1.checklist,
          },
        });
      })
      .catch((err) => {});
  };
  // filterTaskAnalytics = (data) => {
  //
  //   let { reminder } = data;
  //   let { taskOptions } = this.state;

  //   let branchId = localStorage.getItem("selectedBranchId");
  //   const docTypeOpt = {
  //     url: `v1/client/contract/GetCount/` + branchId,
  //   };

  //   docTypeOpt.types = [
  //     types.GET_DOCUMENT_TYPE_SUCCESS,
  //     types.GET_DOCUMENT_TYPE_FAILURE,
  //   ];

  //   if (digitialCount == 0) {
  //     digitialCount = 1;
  //     apiRefresh
  //       .get(docTypeOpt)
  //       .then((res) => {
  //         digitialCount = res.count;
  //         this.setState({
  //           taskOptions: {
  //             ...taskOptions,
  //             reminderCounts: reminder,
  //             digitalCounts: res.count,
  //           },
  //         });
  //         const docTypeOpt1 = {
  //           url: `v1/dashboardbi/ChecklistQuestionnaireCount/` + branchId,
  //         };

  //         docTypeOpt1.types = [
  //           types.GET_DOCUMENT_TYPE_SUCCESS,
  //           types.GET_DOCUMENT_TYPE_FAILURE,
  //         ];

  //         if (OtherCount == 0) {
  //           OtherCount = 1;
  //           apiRefresh
  //             .get(docTypeOpt1)
  //             .then((res1) => {
  //               this.setState({
  //                 taskOptions: {
  //                   ...taskOptions,
  //                   reminderCounts: reminder,
  //                   questionnareCounts: res1.questionnaire,
  //                   digitalCounts: res.count,
  //                   checklistCounts: res1.checklist,
  //                 },
  //               });
  //             })
  //             .catch((err) => {});
  //         }
  //       })
  //       .catch((err) => {});
  //   }
  // };
  filterVisaAnalytics = (visaData) => {
    this.setState({
      visaAnalytics: {
        visa: visaData.map((el) => el.visa),
        count: visaData.map((el) => el.count),
        branchVisaTypeIds: visaData.map((el) => el.branchVisaTypeId),
      },
    });
  };
  getCounts = ({
    taskOptions,
    reminderSection,
    taskSection,
    signatureSection,
    questionnaireSection,
    checklistSection,
  }) => (
    <>
      <Col span={8} offset={4}>
        <Card
          title="QUESTIONNAIRES"
          style={{ cursor: "pointer" }}
          onClick={() => this.onOpenQuestionnairePanel("QUESTIONNAIRES")}
          extra={
            <div className="bi-count-circle color1">
              <span style={{ color: "white" }}>
                {taskOptions.questionnareCounts}
              </span>
            </div>
          }
          className="bi-card"
        />
        <Card
          title="DOCUMENT CHECKLISTS"
          style={{ cursor: "pointer" }}
          onClick={() => this.onChecklistPanel("DOCUMENT CHECKLISTS")}
          extra={
            <div className="bi-count-circle color2">
              <span style={{ color: "white" }}>
                {taskOptions.checklistCounts}
              </span>
            </div>
          }
          className="bi-card"
        />
        <Card
          title="DIGITAL SIGNATURES"
          style={{ cursor: "pointer" }}
          onClick={() => this.onOpenSignaturePanel("Digital Signatures")}
          extra={
            <div className="bi-count-circle color3">
              <span style={{ color: "white" }}>
                {taskOptions.digitalCounts}
              </span>
            </div>
          }
          className="bi-card"
        />
      </Col>
      <Col span={8}>
        <Card
          style={{ cursor: "pointer" }}
          onClick={() => this.onOpenTaskPanel("TODAY'S TASK")}
          title="TODAY'S TASKS"
          extra={
            <div className="bi-count-circle color4">
              <span style={{ color: "white" }}>{taskOptions.todaysCounts}</span>
            </div>
          }
          className="bi-card"
        />
        <Card
          style={{ cursor: "pointer" }}
          title="PENDING TASKS"
          onClick={() => this.onOpenTaskPanel("PENDING TASK")}
          extra={
            <div className="bi-count-circle color5">
              <span style={{ color: "white" }}>
                {taskOptions.pendingCounts}
              </span>
            </div>
          }
          className="bi-card"
        />
        <Card
          style={{ cursor: "pointer" }}
          title="REMINDERS"
          extra={
            <div className="bi-count-circle color6">
              <span style={{ color: "white" }}>
                {reminderSection.reminders.length}
              </span>
            </div>
          }
          className="bi-card"
          onClick={() =>
            this.setState({
              reminderSection: { ...reminderSection, isReminderPanel: true },
              taskSection: { ...taskSection, isTaskPanel: false },
              signatureSection: {
                ...signatureSection,
                isSignaturePanel: false,
              },
              questionnaireSection: {
                ...questionnaireSection,
                isQuestionnairePanel: false,
              },
              checklistSection: {
                ...checklistSection,
                isChecklistPanel: false,
              },
            })
          }
        />
      </Col>
    </>
  );
  getAccountsChart = ({ accountAnalytics, accountAnalyticsFilter }) => (
    <>
      {localStorage.getItem("userSystemPermissions") &&
      JSON.parse(
        decodeURIComponent(
          escape(window.atob(localStorage.getItem("userSystemPermissions")))
        )
      ).find((x) => x.role.toLowerCase() == "accounts").status == 1 ? (
        <div
          style={{
            height: "480px",
            backgroundColor: "white",
            marginTop: 15,
            padding: 10,
          }}
        >
          <div>
            <h4
              style={{
                color: "#144565",
                margin: "0px 0px 10px",
                fontWeight: "600",
                fontSize: 14,
              }}
            >
              Accounts
            </h4>
          </div>
          <div style={{ padding: 0 }}>
            <Form
              ref={this.accountAnalyticRef}
              {...layout}
              name="basic"
              className="d-flex w-100"
              onFinish={() => {
                this.setState({ pageLoading: true });
                this.getAccountAnalytics();
              }}
            >
              <Form.Item name="dateTo" labelAlign="left">
                <DatePicker
                  className="bi-date1"
                  placeholder={"Select Date From"}
                  calendarIcon={null}
                  clearIcon={null}
                  onChange={(e) => this.onAccountAnalyticsChange("dateFrom", e)}
                  format="DD/MM/YYYY"
                  defaultValue={accountAnalyticsFilter.dateFrom}
                  value={accountAnalyticsFilter.dateFrom}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ marginRight: 5, marginLeft: 5 }}>to</span>
              </Form.Item>
              <Form.Item name="dateFrom" labelAlign="left">
                <DatePicker
                  className="bi-date1"
                  placeholder={"Select Date To"}
                  calendarIcon={null}
                  clearIcon={null}
                  defaultValue={accountAnalyticsFilter.dateTo}
                  onChange={(e) => this.onAccountAnalyticsChange("dateTo", e)}
                  format="DD/MM/YYYY"
                  value={accountAnalyticsFilter.dateTo}
                />
              </Form.Item>
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Apply
                </Button>
              </Form.Item>
            </Form>
          </div>

          <div className="donut" style={{ marginTop: 60 }}>
            <Chart
              options={{
                fill: {
                  colors: ["#99d14c", "#de3137"],
                },
                legend: { position: "bottom" },
                colors: ["#99d14c", "#de3137"],
                plotOptions: {
                  pie: {
                    // expandOnClick: true,
                    customScale: 1.1,
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontFamily: "Helvetica, Arial, sans-serif",
                          fontWeight: 600,
                          color: "black",
                        },
                        total: {
                          show: true,
                          label:
                            accountAnalytics.incoming >
                            accountAnalytics.outgoing
                              ? "Incoming"
                              : "Outgoing",
                          color: "black",
                          formatter: function(w) {
                            let values = w.globals.series;
                            return values[0] > values[1]
                              ? values[0]
                              : values[1];
                            // return w.globals.seriesTotals.reduce((a, b) => {
                            //   return a + b;
                            // }, 0);
                          },
                        },
                      },
                    },
                  },
                },
                labels: ["Incoming", "Outgoing"],
                dataLabels: {
                  enabled: false,
                },
                chart: {
                  events: {
                    dataPointSelection: (event, chartContext, config) => {
                      this.getClientAccountsInfo(
                        config.w.config.labels[config.dataPointIndex]
                      );
                      // this.setState({accountAnalytics:{...accountAnalytics,isAccountPanelOpen:true}})
                      // console.log(config.w.config.labels[config.dataPointIndex]);
                    },
                  },
                },
              }}
              series={[accountAnalytics.incoming, accountAnalytics.outgoing]}
              type="donut"
              width="350"
            />
          </div>
        </div>
      ) : null}
    </>
  );
  getClientAccountsInfo = (type) => {
    let { accountAnalyticsFilter, accountAnalytics } = this.state;
    this.setState({ pageLoading: true });
    if (type === "Incoming") {
      this.props
        .onGetIncomingClientDetail({
          clientId: "00000000-0000-0000-0000-000000000000",
          dateFrom: accountAnalyticsFilter.dateFrom.toISOString(),
          dateTo: accountAnalyticsFilter.dateTo.toISOString(),
        })
        .then((res) => {
          this.setState({
            accountAnalytics: {
              ...accountAnalytics,
              clients: res.payload,
              isAccountPanelOpen: true,
              type: type,
            },
            pageLoading: false,
          });
        })
        .catch(() => {
          this.setState({ pageLoading: false });
        });
    }
    if (type === "Outgoing") {
      this.props
        .onGetOutgoingClientDetail({
          clientId: "00000000-0000-0000-0000-000000000000",
          dateFrom: accountAnalyticsFilter.dateFrom.toISOString(),
          dateTo: accountAnalyticsFilter.dateTo.toISOString(),
        })
        .then((res) => {
          this.setState({
            accountAnalytics: {
              ...accountAnalytics,
              clients: res.payload,
              isAccountPanelOpen: true,
              type: type,
            },
            pageLoading: false,
          });
        })
        .catch(() => {
          this.setState({ pageLoading: false });
        });
    }
  };
  getAccountAnalytics = () => {
    let { onGetAccountsAnalytics } = this.props;
    let { accountAnalyticsFilter } = this.state;
    let { dateFrom, dateTo } = accountAnalyticsFilter;
    onGetAccountsAnalytics({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
    });
  };
  getVisaAnalytics = () => {
    let { onGetVisaAnalytics } = this.props;
    let { visaAnalyticsFilter } = this.state;
    let { dateFrom, dateTo } = visaAnalyticsFilter;
    onGetVisaAnalytics({ dateFrom, dateTo });
  };
  getVisaAnalyticChart = ({ visaAnalytics, visaAnalyticsFilter }) => (
    <div
      style={{
        height: "480px",
        backgroundColor: "white",
        marginTop: 15,
        marginRight: 10,
        padding: 20,
      }}
    >
      <div>
        <h4
          style={{
            color: "#144565",
            margin: "0px 5px 10px",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          Visa Analytics
        </h4>
      </div>
      <div style={{ padding: 10, position: "relative" }}>
        <Form
          ref={this.clientAnalyticRef}
          {...layout}
          name="basic"
          className="d-flex w-100"
          onFinish={() => {
            this.setState({ pageLoading: true });
            this.getVisaAnalytics();
          }}
        >
          <Form.Item name="dateTo" labelAlign="left">
            <DatePicker
              className="bi-date"
              placeholder={"Select Date From"}
              calendarIcon={null}
              clearIcon={null}
              onChange={(e) => this.onVisaAnalyticChange("dateFrom", e)}
              format="DD/MM/YYYY"
              defaultValue={visaAnalyticsFilter.dateFrom}
              value={visaAnalyticsFilter.dateFrom}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ marginRight: 5, marginLeft: 5 }}>to</span>
          </Form.Item>
          <Form.Item name="dateFrom" labelAlign="left">
            <DatePicker
              className="bi-date"
              placeholder={"Select Date To"}
              calendarIcon={null}
              clearIcon={null}
              defaultValue={visaAnalyticsFilter.dateTo}
              onChange={(e) => this.onVisaAnalyticChange("dateTo", e)}
              format="DD/MM/YYYY"
              value={visaAnalyticsFilter.dateTo}
            />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              Apply
            </Button>
          </Form.Item>
        </Form>
        {/* <Tooltip placement="topLeft" title={`Coming Soon`}>
          <img
            src={download}
            width="20"
            style={{
              position: "absolute",
              top: 17,
              cursor: "pointer",
              right: 0,
            }}
          />
        </Tooltip> */}
      </div>

      <div className="mixed-chart">
        <Chart
          options={{
            chart: {
              id: "basic-bar",
              toolbar: {
                tools: {
                  download: `<img hidden src=${download} class="visa-ico-download" width="20">`,
                },
              },
              events: {
                dataPointSelection: (event, chartContext, config) =>
                  this.onGetVisaClients(config),
              },
            },
            xaxis: {
              categories: visaAnalytics.visa,
              // labels: {
              //   showDuplicates: true,
              //   style: {
              //     overflow:'hidden',
              //     textOverflow:'ellipsis',
              //     whiteSpace:'nowrap',
              //     width:50
              //   },
              // },
            },
            fill: {
              colors: ["#3FCEB0", "#212121"],
            },
          }}
          series={[
            {
              name: "Client",
              data: visaAnalytics.count,
            },
          ]}
          type="bar"
          height="300"
        />
      </div>
    </div>
  );
  getIdleAndActiveSince = ({ activeTab, activeSince, idleSince }) => (
    <div
      style={{
        height: "480px",
        backgroundColor: "white",
        marginTop: 15,
        padding: 10,
      }}
    >
      <Tabs defaultActiveKey={activeTab}>
        <TabPane tab="Idle Since (Days)" key="1">
          <Chart
            options={{
              chart: {
                type: "bar",
                height: 350,
                toolbar: {
                  tools: {
                    download: `<img hidden src=${download} class="idle-ico-download" width="20">`,
                  },
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                  horizontal: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: idleSince.categories,
              },
            }}
            series={[
              {
                data: idleSince.data,
                name: "Days",
              },
            ]}
            type="bar"
            height={350}
          />
        </TabPane>
        <TabPane tab="Active Since (Days)" key="2">
          <Chart
            options={{
              chart: {
                type: "bar",
                height: 350,
                toolbar: {
                  tools: {
                    download: `<img hidden src=${download} class="status-ico-download" width="20">`,
                  },
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                  horizontal: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: activeSince.categories,
              },
            }}
            series={[
              {
                data: activeSince.data,
                name: "Client",
              },
            ]}
            type="bar"
            height={350}
          />
        </TabPane>
      </Tabs>
    </div>
  );

  getVisaStatusAnalyticChart = ({
    visaStatusAnalytics,
    visaStatus,
    visaAnalyticsFilter,
  }) => (
    <div
      style={{
        height: "480px",
        backgroundColor: "white",
        marginTop: 15,
        marginRight: 10,
        padding: 10,
      }}
    >
      <div style={{ padding: 10 }}>
        <span>
          <h4
            style={{
              color: "#144565",
              margin: "-8px 5px 10px",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            Visa Status Analytics
          </h4>
        </span>
        <Select
          showSearch
          style={{ width: 280, borderRadius: 0, marginRight: 10 }}
          placeholder="Select Status"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(val, record) => this.onChangeVisaStatus(val, record)}
        >
          {visaStatus.length &&
            visaStatus.map(({ name, id }) => (
              <Option value={id}>{name}</Option>
            ))}
        </Select>
      </div>
      <div style={{ padding: 10 }}>
        <Form
          ref={this.clientAnalyticRef}
          {...layout}
          name="basic"
          className="d-flex w-100"
        >
          <Form.Item name="dateTo" labelAlign="left">
            <DatePicker
              className="bi-date"
              placeholder={"Select Date From"}
              calendarIcon={null}
              clearIcon={null}
              format="DD/MM/YYYY"
              defaultValue={visaAnalyticsFilter.dateFrom}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ marginRight: 5, marginLeft: 5 }}>to</span>
          </Form.Item>
          <Form.Item name="dateFrom" labelAlign="left">
            <DatePicker
              className="bi-date"
              placeholder={"Select Date To"}
              calendarIcon={null}
              clearIcon={null}
              defaultValue={visaAnalyticsFilter.dateTo}
              // onChange={(e) => this.onVisaAnalyticChange("dateTo", e)}
              format="DD/MM/YYYY"
              // value={visaAnalyticsFilter.dateTo}
            />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ paddingLeft: 10, paddingRight: 10 }}
              onClick={() => {
                this.setState({ pageLoading: true });
                this.props
                  .onGetVisaStatuses()
                  .then(() => {
                    this.setState({ pageLoading: false });
                  })
                  .catch(() => {
                    this.setState({ pageLoading: false });
                  });
              }}
            >
              Apply
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div>
        <Chart
          options={{
            chart: {
              id: "basic-bar",
              toolbar: {
                tools: {
                  download: `<img src=${download} class="status-ico-download" width="20">`,
                },
              },
            },
            xaxis: {
              categories: visaStatusAnalytics.data.map((el) => el.date),
            },
            fill: {
              colors: ["#98cb4a", "#98cb4a"],
            },
          }}
          series={[
            {
              name: visaStatusAnalytics.selectedStatus,
              data: visaStatusAnalytics.data.map((el) => el.count),
            },
          ]}
          type="bar"
          width="100%"
          height="350"
        />
      </div>
    </div>
  );
  onChangeVisaStatus = (val, record) => {
    let { visaStatusAnalytics } = this.state;
    this.setState(
      {
        visaStatusAnalytics: {
          ...visaStatusAnalytics,
          selectedStatus: record.children,
        },
        pageLoading: true,
      },
      () =>
        this.props.onGetVisaStatusAnalytics({
          statusId: val,
        })
    );
  };

  getActiveBalanceChart = ({ activeBalanceAnalytics, visaAnalyticsFilter }) => (
    <>
      {localStorage.getItem("userSystemPermissions") &&
      JSON.parse(
        decodeURIComponent(
          escape(window.atob(localStorage.getItem("userSystemPermissions")))
        )
      ).find((x) => x.role.toLowerCase() == "accounts").status == 1 ? (
        <div
          style={{
            height: "480px",
            backgroundColor: "white",
            marginTop: 15,
            padding: 10,
          }}
        >
          <div>
            <span>
              <h4
                style={{
                  color: "#144565",
                  margin: "0px 5px 10px",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                Active Client Balance
              </h4>
            </span>
            <span>
              <h4
                style={{
                  color: "#26a0fc",
                  float: "right",
                  marginTop: "-29px",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                Total: <span>{activeBalanceAnalytics.total.toFixed(2)}</span>
              </h4>
            </span>
          </div>
          <div style={{ padding: 10 }}>
            <Form
              ref={this.clientAnalyticRef}
              {...layout}
              name="basic"
              className="d-flex w-100"
              // onFinish={() => {
              //   this.setState({ pageLoading: true });
              //   this.getVisaAnalytics();
              // }}
            >
              <Form.Item name="dateTo" labelAlign="left">
                <DatePicker
                  className="bi-date"
                  placeholder={"Select Date From"}
                  calendarIcon={null}
                  clearIcon={null}
                  // onChange={(e) => this.onVisaAnalyticChange("dateFrom", e)}
                  format="DD/MM/YYYY"
                  defaultValue={visaAnalyticsFilter.dateFrom}
                  // value={visaAnalyticsFilter.dateFrom}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ marginRight: 5, marginLeft: 5 }}>to</span>
              </Form.Item>
              <Form.Item name="dateFrom" labelAlign="left">
                <DatePicker
                  className="bi-date"
                  placeholder={"Select Date To"}
                  calendarIcon={null}
                  clearIcon={null}
                  defaultValue={visaAnalyticsFilter.dateTo}
                  // onChange={(e) => this.onVisaAnalyticChange("dateTo", e)}
                  format="DD/MM/YYYY"
                  // value={visaAnalyticsFilter.dateTo}
                />
              </Form.Item>
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                  onClick={() => {
                    this.setState({ pageLoading: true });
                    this.props
                      .onGetActiveBalanceAnalytics()
                      .then((res) => {
                        this.setState({ pageLoading: false });
                      })
                      .catch((err) => {
                        this.setState({ pageLoading: false });
                      });
                  }}
                >
                  Apply
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="donut abc" style={{ marginTop: 15 }}>
            <Chart
              options={{
                legend: {
                  formatter: function(val, opts) {
                    return (
                      val + " - " + opts.w.globals.series[opts.seriesIndex]
                    );
                  },
                },
                plotOptions: {
                  pie: {
                    // expandOnClick: true,
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontFamily: "Helvetica, Arial, sans-serif",
                          fontWeight: 600,
                          color: "black",
                        },
                      },
                    },
                  },
                },
                labels: activeBalanceAnalytics.labels,
                dataLabels: {
                  enabled: false,
                },
                chart: {
                  events: {
                    beforeMount: function(chartContext, config) {},
                    mouseMove: function(event, chartContext, config) {},
                  },
                },
              }}
              series={activeBalanceAnalytics.series}
              type="donut"
              width="85%"
            />
          </div>
        </div>
      ) : null}
    </>
  );

  CompletedTasks = (task, status) => {
    let updateTitle = task.id;
    let { taskSection, taskOptions } = this.state;
    this.setState({ pageLoading: true });
    const completed = {
      id: updateTitle,
    };
    this.props.onUpdateCompletedTask(completed).then(() => {
      if (taskSection.type === "TODAY'S TASK") {
        let todaysData = taskSection.todaysTask.filter(
          (el) => el.id !== updateTitle && !el.isCompleted
        );
        this.setState(
          {
            pageLoading: false,
            taskSection: {
              ...taskSection,
              todaysTask: todaysData,
            },
            taskOptions: {
              ...taskOptions,
              todaysCounts: todaysData.length,
            },
          },
          () => message.success("Successfully Completed!")
        );
      }
      if (taskSection.type === "PENDING TASK") {
        let pendingData = taskSection.pendingTask.filter(
          (el) => el.id !== updateTitle && !el.isCompleted
        );
        this.setState(
          {
            pageLoading: false,
            taskSection: {
              ...taskSection,
              pendingTask: pendingData,
            },
            taskOptions: {
              ...taskOptions,
              pendingCounts: pendingData.length,
            },
          },
          () => message.success("Successfully Completed!")
        );
      }
    });
  };
  onOpenTaskPanel = (type) => {
    // let timezone = moment.tz.guess();
    let {
      taskSection,
      reminderSection,
      signatureSection,
      questionnaireSection,
      checklistSection,
    } = this.state;
    const currentDate = moment(new Date()).format("l");
    let data = taskSection.tasks.filter(
      (el) =>
        moment(currentDate).isSame(moment(el.taskDate).format("l")) &&
        !el.isCompleted
    );
    let pendingData = taskSection.tasks.filter(
      (el) => moment(currentDate) > moment(el.taskDate) && !el.isCompleted
    );
    this.setState({
      taskSection: {
        ...taskSection,
        todaysTask: data,
        pendingTask: pendingData,
        isTaskPanel: true,
        type: type,
      },
      reminderSection: { ...reminderSection, isReminderPanel: false },
      signatureSection: { ...signatureSection, isSignaturePanel: false },
      questionnaireSection: {
        ...questionnaireSection,
        isQuestionnairePanel: false,
      },
      checklistSection: { ...checklistSection, isChecklistPanel: false },

      pageLoading: false,
    });
  };

  onOpenSignaturePanel = (type) => {
    // let timezone = moment.tz.guess();
    let {
      taskSection,
      reminderSection,
      signatureSection,
      questionnaireSection,
      checklistSection,
    } = this.state;
    const currentDate = moment(new Date()).format("l");
    let data = taskSection.tasks.filter(
      (el) =>
        moment(currentDate).isSame(moment(el.taskDate).format("l")) &&
        !el.isCompleted
    );
    let pendingData = taskSection.tasks.filter(
      (el) => moment(currentDate) > moment(el.taskDate) && !el.isCompleted
    );
    this.setState({
      taskSection: {
        ...taskSection,
        isTaskPanel: true,
      },
      reminderSection: { ...reminderSection, isReminderPanel: false },
      taskSection: { ...taskSection, isTaskPanel: false },
      signatureSection: { ...signatureSection, isSignaturePanel: true },
      questionnaireSection: {
        ...questionnaireSection,
        isQuestionnairePanel: false,
      },
      checklistSection: { ...checklistSection, isChecklistPanel: false },

      pageLoading: false,
    });
  };

  onOpenQuestionnairePanel = (type) => {
    // let timezone = moment.tz.guess();
    let {
      taskSection,
      reminderSection,
      signatureSection,
      questionnaireSection,
      checklistSection,
    } = this.state;
    const currentDate = moment(new Date()).format("l");
    let data = taskSection.tasks.filter(
      (el) =>
        moment(currentDate).isSame(moment(el.taskDate).format("l")) &&
        !el.isCompleted
    );
    let pendingData = taskSection.tasks.filter(
      (el) => moment(currentDate) > moment(el.taskDate) && !el.isCompleted
    );
    this.setState({
      taskSection: {
        ...taskSection,
        isTaskPanel: true,
      },
      reminderSection: { ...reminderSection, isReminderPanel: false },
      taskSection: { ...taskSection, isTaskPanel: false },
      signatureSection: { ...signatureSection, isSignaturePanel: false },
      questionnaireSection: {
        ...questionnaireSection,
        isQuestionnairePanel: true,
      },
      checklistSection: { ...checklistSection, isChecklistPanel: false },
      pageLoading: false,
    });
  };
  onChecklistPanel = (type) => {
    // let timezone = moment.tz.guess();
    let {
      taskSection,
      reminderSection,
      signatureSection,
      questionnaireSection,
      checklistSection,
    } = this.state;
    const currentDate = moment(new Date()).format("l");
    let data = taskSection.tasks.filter(
      (el) =>
        moment(currentDate).isSame(moment(el.taskDate).format("l")) &&
        !el.isCompleted
    );
    let pendingData = taskSection.tasks.filter(
      (el) => moment(currentDate) > moment(el.taskDate) && !el.isCompleted
    );
    this.setState({
      taskSection: {
        ...taskSection,
        isTaskPanel: true,
      },
      reminderSection: { ...reminderSection, isReminderPanel: false },
      taskSection: { ...taskSection, isTaskPanel: false },
      signatureSection: { ...signatureSection, isSignaturePanel: false },
      questionnaireSection: {
        ...questionnaireSection,
        isQuestionnairePanel: false,
      },
      checklistSection: { ...checklistSection, isChecklistPanel: true },
      pageLoading: false,
    });
  };
  showModal = (modalTypeName, id, data) => {
    let { modalConfig } = this.state;
    this.setState({
      modalConfig: {
        ...modalConfig,
        modalType: modalTypeName,
        isModalOpen: true,
        updateTitle: id,
        updateData: data,
      },
    });
  };
  handleCancel = () => {
    let { modalConfig } = this.state;
    this.setState({ modalConfig: { ...modalConfig, isModalOpen: false } });
  };
  updateTaskState = (key, data) => {
    let { taskSection } = this.state;
    this.setState({ taskSection: { ...taskSection, [key]: data } });
  };
  removeTask = (updateTitle) => {
    let { taskSection, modalConfig } = this.state;
    this.setState({ pageLoading: true });
    const remove = {
      id: updateTitle,
      delete: true,
    };
    this.props.onRemoveTasks(remove).then(() => {
      this.setState({
        pageLoading: false,
        taskSection: {
          ...taskSection,
          todaysTask: taskSection.todaysTask.filter(
            (el) => el.id != updateTitle
          ),
          pendingTask: taskSection.todaysTask.filter(
            (el) => el.id != updateTitle
          ),
        },
        modalConfig: { ...modalConfig, isModalOpen: false },
      });
      message.success("Successfully Deleted!");
    });
  };
  closeTaskPanel = () => {
    let { taskSection } = this.state;
    this.setState({ taskSection: { ...taskSection, isTaskPanel: false } });
  };
  closeReminderPanel = () => {
    let { reminderSection } = this.state;
    this.setState({
      reminderSection: { ...reminderSection, isReminderPanel: false },
    });
  };
  closeSignaturePanel = () => {
    let { signatureSection } = this.state;
    this.setState({
      signatureSection: { ...signatureSection, isSignaturePanel: false },
    });
  };
  closeQuestionnairePanel = () => {
    let { questionnaireSection } = this.state;
    this.setState({
      questionnaireSection: {
        ...questionnaireSection,
        isQuestionnairePanel: false,
      },
    });
  };
  closeChecklistPanel = () => {
    let { checklistSection } = this.state;
    this.setState({
      checklistSection: { ...checklistSection, isChecklistPanel: false },
    });
  };
  getClientAnalyticsTable = ({ clientAnalytics }) => (
    <div className="bi-table-card">
      <div className="heading-bi">
        <span>Client Added on : {clientAnalytics.addedOn}</span>
      </div>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={this.clientColumns}
        dataSource={clientAnalytics.clients}
        pagination={false}
      />
    </div>
  );
  getVisaClientAnalyticsTable = ({ visaAnalytics }) => (
    <div className="bi-table-card">
      <div className="heading-bi">
        <span>{visaAnalytics.visaName}</span>
      </div>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={this.visaClientColumns}
        dataSource={visaAnalytics.clients}
        pagination={false}
      />
    </div>
  );
  getAccountClientTable = ({ accountAnalytics }) => (
    <div className="bi-table-card">
      <div className="heading-bi">
        {accountAnalytics.type && <span>{accountAnalytics.type}</span>}
      </div>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={this.accountClientsColumns}
        dataSource={accountAnalytics.clients}
        pagination={false}
      />
    </div>
  );
  openClientFile = (id, clientId, memberType, familyId) => {
    localStorage.setItem("clientprofileid", JSON.stringify(id));
    localStorage.setItem("familyId", familyId);

    if (memberType === "Client") {
      localStorage.setItem("clientProfileIdMain", clientId);
      localStorage.setItem("familyId", familyId);
      this.props.history.push({
        pathname: "/profile",
        state: { id: id },
      });
    } else if (memberType === "Spouse") {
      this.props.history.push({
        pathname: "/partner-profile",
        state: { id: clientId, familyId: familyId },
      });
    } else if (memberType === "Child") {
      this.props.history.push({
        pathname: "/client-family-member",
        state: { id: clientId, familyId: familyId },
      });
    }
  };
  closeClientPanel = () => {
    let { clientAnalytics } = this.state;
    this.setState({
      clientAnalytics: { ...clientAnalytics, isClientPanelOpen: false },
    });
  };
  render() {
    let {
      pageLoading,
      taskSection,
      modalConfig,
      reminderSection,
      signatureSection,
      questionnaireSection,
      checklistSection,
      clientAnalytics,
      visaAnalytics,
      accountAnalytics,
      admintoken,
    } = this.state;
    let {
      isModalOpen,
      modalType,
      modalTitle,
      updateData,
      updateTitle,
    } = modalConfig;
    let {
      onGetTaskComments,
      onAddTaskComment,
      onGetTaskFollowers,
      onGetPotentialClient,
      onUpdetaTasks,
      onGetDailyTasks,
      dailyTaskRes,
      onGetProcessingPersons,
      onAddTaskFollower,
      onRemoveFollower,
      onRemoveTaskClient,
      onSearchClient,
      onGetClientProfile,
    } = this.props;
    return (
      <Spin size="large" spinning={pageLoading}>
        <div className="DashboardBiWrapper" style={{ padding: 10 }}>
          {/* <h3 style={{ color: "#144565", margin: "20px 15px 30px" }}>
            <DashboardOutlined style={{ color: "#06aee3" }} /> Dashboard
          </h3> */}
          <Row style={{ marginBottom: 30, marginTop: 40 }}>
            {this.getCounts(this.state)}
          </Row>
          {taskSection.isTaskPanel ? (
            <Row>
              <TodaysTask
                title={taskSection.type}
                showModal={this.showModal}
                data={
                  taskSection.type === "TODAY'S TASK"
                    ? taskSection.todaysTask
                    : taskSection.pendingTask
                }
                ComletedTasks={this.CompletedTasks}
                getTasksComments={onGetTaskComments}
                addTaskComment={onAddTaskComment}
                getTaskFollowers={onGetTaskFollowers}
                onGetPotentialClient={onGetPotentialClient}
                updateTaskState={this.updateTaskState}
                onRemoveFollower={onRemoveFollower}
                closeTaskPanel={this.closeTaskPanel}
                onRemoveTaskClient={onRemoveTaskClient}
                onSearchClient={onSearchClient}
                onGetClientProfile={onGetClientProfile}
              />
            </Row>
          ) : null}
          {reminderSection.isReminderPanel ? (
            <Row>
              <Reminders
                showModal={this.showModal}
                onGetReminderTasks={this.props.onGetReminder}
                reminderTaskRes={this.props.reminderTaskRes}
                onRemoveReminderTask={this.props.onRemoveReminderTask}
                closeReminderPanel={this.closeReminderPanel}
                reminderData={reminderSection.reminders}
                onUpdateDashboardReminderStatus={
                  this.props.onUpdateDashboardReminderStatus
                }
              />
            </Row>
          ) : null}
          {signatureSection.isSignaturePanel ? (
            <Row>
              <Signature
                showModal={this.showModal}
                closeSignaturePanel={this.closeSignaturePanel}
              />
            </Row>
          ) : null}
          {questionnaireSection.isQuestionnairePanel ? (
            <Row>
              <Questionnaire
                onGetPotentialClient={this.props.onGetPotentialClient}
                onGetUpdateBol={this.props.onGetUpdateBol}
                showModal={this.showModal}
                closeSignaturePanel={this.closeQuestionnairePanel}
              />
            </Row>
          ) : null}
          {checklistSection.isChecklistPanel ? (
            <Row>
              <Checklist
                onGetPotentialClient={this.props.onGetPotentialClient}
                onGetUpdateBol={this.props.onGetUpdateBol}
                showModal={this.showModal}
                closeSignaturePanel={this.closeChecklistPanel}
              />
            </Row>
          ) : null}
          <Row>
            <Col span={16}>{this.clientAnalyticChart(this.state)}</Col>
            <Col span={8}>{this.getAccountsChart(this.state)}</Col>{" "}
          </Row>
          {clientAnalytics.isClientPanelOpen && (
            <Row>
              <Col span={24}>
                <div>
                  <a
                    style={{
                      position: "absolute",
                      top: 22,
                      right: 10,
                    }}
                  >
                    <CloseCircleOutlined
                      onClick={() => this.closeClientPanel()}
                      style={{ fontSize: "20px" }}
                    />
                  </a>
                </div>
                {this.getClientAnalyticsTable(this.state)}
              </Col>
            </Row>
          )}
          {accountAnalytics.isAccountPanelOpen && (
            <Row>
              <Col span={24}>
                <div>
                  <a
                    style={{
                      position: "absolute",
                      top: 22,
                      right: 10,
                    }}
                  >
                    <CloseCircleOutlined
                      onClick={() =>
                        this.setState({
                          accountAnalytics: {
                            ...accountAnalytics,
                            isAccountPanelOpen: false,
                          },
                        })
                      }
                      style={{ fontSize: "20px" }}
                    />
                  </a>
                </div>
                {this.getAccountClientTable(this.state)}
              </Col>
            </Row>
          )}
          <Row>
            <Col span={12}>{this.getVisaAnalyticChart(this.state)}</Col>
            <Col span={12}>{this.getIdleAndActiveSince(this.state)}</Col>
          </Row>
          {visaAnalytics.isVisaPanelOpen && (
            <Row>
              <Col span={24}>
                <div>
                  <a
                    style={{
                      position: "absolute",
                      top: 22,
                      right: 10,
                    }}
                  >
                    <CloseCircleOutlined
                      onClick={() =>
                        this.setState({
                          visaAnalytics: {
                            ...visaAnalytics,
                            isVisaPanelOpen: false,
                          },
                        })
                      }
                      style={{ fontSize: "20px" }}
                    />
                  </a>
                </div>
                {this.getVisaClientAnalyticsTable(this.state)}
              </Col>
            </Row>
          )}
          <Row>
            <Col span={12}>{this.getVisaStatusAnalyticChart(this.state)}</Col>
            <Col span={12}>{this.getActiveBalanceChart(this.state)}</Col>
          </Row>
        </div>
        <div className="reminder-model">
          <Modal
            className="reminder-model-main"
            title={
              (modalType === "add-reminder" && "ADD REMINDER") ||
              (modalType === "add-task" && "ADD TASKS") ||
              (modalType === "for-update" && "UPDATE") ||
              (modalType === "for-update-date" && "RESCHEDULE TASK") ||
              (modalType === "confirm-delete" && "CONFIRMATION") ||
              (modalType === "add-follower" && "ADD FOLLOWER") ||
              (modalType === "update-reminder" && "UPDATE REMINDER")
            }
            visible={isModalOpen}
            onCancel={this.handleCancel}
            Header={false}
            footer={false}
          >
            {modalType === "update-reminder" && (
              <UpdateReminderModal
                onAddReminderTask={this.props.onAddReminderTask}
                onGetReminderTasks={this.props.onGetReminder}
                searchClientData={this.props.searchClientData}
                potentialClientsData={this.props.potentialClientsData}
                onSearchClient={this.props.onSearchClient}
                onSearchPotentialClient={this.props.onSearchPotentialClient}
                handleCancel={this.handleCancel}
                reminderData={updateData}
                onUpdateReminderDetail={this.props.onUpdateReminderDetail}
              />
            )}
            {modalType === "for-update" && (
              <UpdateModal
                onUpdetaTasks={onUpdetaTasks}
                dailyTaskRes={dailyTaskRes}
                updateTitle={updateTitle}
                updateData={updateData}
                setReload={(val) => window.location.reload()}
                reloadFlag={pageLoading}
                handleCancel={this.handleCancel}
              />
            )}
            {modalType === "for-update-date" && (
              <UpdateDateModal
                onUpdetaTasks={onUpdetaTasks}
                dailyTaskRes={dailyTaskRes}
                updateTitle={updateTitle}
                updateData={updateData}
                setReload={(val) => this.setState({ temp: val })}
                reloadFlag={pageLoading}
                handleCancel={this.handleCancel}
              />
            )}
            {modalType === "add-follower" && (
              <AddFollowerModal
                onUpdetaTasks={onUpdetaTasks}
                dailyTaskRes={dailyTaskRes}
                updateTitle={updateTitle}
                updateData={updateData}
                getAllUsers={onGetProcessingPersons}
                addTaskFollower={onAddTaskFollower}
                users={modalConfig ? modalConfig.users : []}
                handleCancel={this.handleCancel}
              />
            )}
            {modalType === "confirm-delete" && (
              <ComfirmToDelete
                removeTask={this.removeTask}
                updateTitle={updateTitle}
                handleCancel={this.handleCancel}
              />
            )}
          </Modal>
        </div>
      </Spin>
    );
  }
}

export default DashboardBi;
