import React, { Fragment, useState, useEffect } from "react";
import { Form, Input, Button, message, Row, Col, Spin, Upload } from "antd";
import AddDocumentsCheckList from "../../Clients/EmployerDocuments/DocumentsCheckList/AddDocumentsCheckList";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";
import {
  CloseCircleOutlined,
  PlusOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import moment from "moment";
import activityData from "../ClientActivity/ActivityData";

function onRequiredChange(e) {
  console.log(`checked = ${e.target.checked}`);
}

const layout = {
  labelCol: { span: 5, offset: 0 },
  wrapperCol: { span: 13, offset: 6 },
};
const layoutUpload = {
  labelCol: { span: 5, offset: 0 },
  wrapperCol: { span: 24 },
};

var counter = 1;

const DocumentChecklistForm = ({
  handleCancel,
  onSendEmailLink,
  clientprofileid,
  linkdata,
  onAddEmailDocument,
  checklistName,
  emailUrl,
  fromClient,
  fromPotentialClient,
  fromPotentialEmployer,
  fromEmployer,
  linkInfo,
  url,
  view,
  clientName,
}) => {
  const [letterString, setLetterString] = useState("");
  const [to, setTo] = useState("");
  const [ccc, setCcc] = useState("");
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState({
    attachmentName: "",
    attachmentUrl: "",
  });
  const [showAttachmentsData, setShowAttachmentsData] = useState([]);
  const [emailFile, SetEmailFile] = useState([]);
  const [signatureText, setSignatureText] = useState("");
  const [tempFileList, setTempFileList] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      subject: `Document Checklist ${checklistName}`,
    });
    var http =
      window.location.host == "localhost:3000" ? "http://" : "https://";
    var URL =
      http + window.location.host + "/CheckList/index.htm?param=" + linkdata;

    const docTypeOpt = {
      url: `v1/client/email/Subject/` + clientprofileid,
    };
    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    setLoading(true);

    apiRefresh
      .get(docTypeOpt)
      .then((res) => {
        setTo(res.email);
        form.setFieldsValue({ to: res.email });

        const checklistTemp = {
          url: `v1/template/documentCheckList`,
        };
        checklistTemp.types = [
          types.GET_DOCUMENT_TYPE_SUCCESS,
          types.GET_DOCUMENT_TYPE_FAILURE,
        ];

        apiRefresh
          .get(checklistTemp)
          .then((result) => {
            const signatureOpt = {
              url: `v1/users/UserSignature`,
            };
            signatureOpt.types = [
              "GET_SIGNATURE_SUCCESS",
              "GET_SIGNATURE_FAILURE",
            ];

            apiRefresh.get(signatureOpt).then((response) => {
              setSignatureText(response.signatureText);

              var temp =
                result && result.items && result.items.length > 0
                  ? result.items[0].content
                  : "";
              var paramArray = [];
              var param1 = new Object();
              param1.key = "ClientId";
              param1.value = clientprofileid;
              paramArray.push(param1);
              var param2 = new Object();
              param2.key = "UserId";
              param2.value = localStorage.getItem("userId");
              paramArray.push(param2);
              var param3 = new Object();
              param3.key = "Link";
              param3.value = linkdata;
              paramArray.push(param3);
              var data = new Object();
              data.templateName = temp
                ? temp.replace(
                    "@CurrentDate",
                    moment(new Date()).format("DD/MM/YYYY")
                  )
                : "";
              data.parameters = paramArray;

              const docTypeOpt = {
                url: `v1/HtmlTemplate/SetAnyTemplate`,
              };

              docTypeOpt.types = [
                types.GET_DOCUMENT_TYPE_SUCCESS,
                types.GET_DOCUMENT_TYPE_FAILURE,
              ];
              apiRefresh.post(docTypeOpt, data).then((resp) => {
                setLoading(false);
                setLetterString(resp + "<br/>" + response.signatureText);
              });
            });
            // temp = temp.replace("@ClientName", res.clientName);
            // temp = temp.replaceAll("@ClientLink", URL);
          })
          .catch((err) => {
            let client = JSON.parse(sessionStorage.getItem("profileData"));
            let content = `<p>Hi @ClientName</p><p>Attached below is your document checklist ,</p><p>Please provide all required documents</p><p>Please <a href="@ClientLink" style="background-color: rgb(255, 255, 255);">click here</a> to upload your documents.</p><p><br></p>`;
            content = content.replace(
              "@ClientName",
              client ? client.fullName : ""
            );
            content = content.replaceAll("@ClientLink", linkInfo);
            setLoading(false);
            setLetterString(content);
          });
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    let formData = new FormData();
    var fs = require("fs");

    if (tempFileList && tempFileList.length > 0) {
      if (counter == tempFileList.length || tempFileList.length == 1) {
        setLoading(true);
        let attachmentUrl = attachmentsData.attachmentUrl;
        let attachmentName = attachmentsData.attachmentName;
        formData.append("BranchId", localStorage.getItem("selectedBranchId"));
        setFileList(tempFileList);
        for (var i = 0; i < tempFileList.length; i++) {
          formData.append(
            "files",
            tempFileList[i].originFileObj
            // fileList[i].originFileObj.uid
          );
        }

        const docTypeOpt = {
          url: "v1/document/MultiUploadWithFileName",
        };
        docTypeOpt.types = [
          "ADD_MULTI_DOUCMENTS_SUCCESS",
          "ADD_MULTI_DOUCMENTS_FAILURE",
        ];

        apiRefresh
          .post(docTypeOpt, formData)
          .then((res) => {
            for (var i = 0; i < res.length; i++) {
              let pdfData = {
                url: res[i].fileUrl,
                name: res[i].fileName,
                type: res[i].contentType ?? "application/octet-stream",
              };
              setShowAttachmentsData((prevState) => [...prevState, pdfData]);
              let semiColon = "";
              if (attachmentName !== "") {
                semiColon = ";";
              }
              let fileName = res[i].fileUrl.substring(
                res[i].fileUrl.lastIndexOf("/") + 1
              );
              let ext = fileName.split(".");
              attachmentName +=
                semiColon +
                "." +
                ext[1] +
                ":" +
                res[i].contentType +
                ":" +
                fileName;
              attachmentUrl += semiColon + res[i].fileUrl;
            }
            setAttachmentsData({
              attachmentUrl: attachmentUrl,
              attachmentName: attachmentName,
            });
            setLoading(false);

            message.success("Successfully Added!");
          })
          .catch(() => setLoading(false));
      } else {
        counter++;
      }
    }
  }, [tempFileList]);

  const onFinish = (values) => {
    setLoading(true);

    console.log("Received values of form:", values);

    if (values.subject == "") {
      message.warning("Please add subject");
      return;
    }
    if (ccc && ccc.split("@").length > 2) {
      if (ccc.split(";").length == 1) {
        message.error('Multiple emails should be seprated by ";"!');
        return;
      }
    }
    // if (bcc && bcc.split("@").length > 2) {
    //   if (_bcc.split(";").length == 1) {
    //     message.error('Multiple emails should be seprated by ";"!');
    //     return;
    //   }
    // }
    const formData = new FormData();
    formData.append("Message", letterString);
    formData.append("Subject", values.subject);
    formData.append("From", localStorage.getItem("selectedBranchName"));
    formData.append("Priority", "10");
    formData.append("FrequencyCode", "employer");
    formData.append("ModuleId", "10");
    formData.append(`Recipients[0].name`, to);
    formData.append(`Recipients[0].type`, "To");
    formData.append(`Recipients[1].name`, ccc ?? "");
    formData.append(`Recipients[1].type`, "CC");
    if (fromPotentialClient) formData.append(`IsPotential`, true);
    // formData.append("Attachments", []);

    for (var i = 0; i < showAttachmentsData.length; i++) {
      formData.append(`Attachments[${i}].blobURL`, showAttachmentsData[i].url);
      formData.append(`Attachments[${i}].name`, showAttachmentsData[i].name);
      formData.append(`Attachments[${i}].type`, showAttachmentsData[i].type);
    }

    var http =
      window.location.host == "localhost:3000" ? "http://" : "https://";
    var URL =
      http + window.location.host + "/CheckList/index.htm?param=" + linkdata;

    // let clientprofileid = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");

    if (checklistName) {
      let linkPayload = {
        clientId: clientprofileid,
        familyId:
          view && view === "client"
            ? familyId
            : "00000000-0000-0000-0000-000000000000",
        linkType: 2,
        link: URL,
        name: checklistName,
      };

      const linkOpt = {
        url: `v1/client/Link`,
      };
      linkOpt.types = ["SAVE_LINK_SUCCES", "SAVE_LINK_FAILURE"];

      apiRefresh
        .post(linkOpt, linkPayload)
        .then((res) => {})
        .catch((err) => {});
    }

    let userId = localStorage.getItem("userId");

    const draftData = {
      id: 0,
      emailMessage: letterString || "",
      isRead: true,
      attachmentName: attachmentsData.attachmentName || "",
      attachmentUrl: attachmentsData.attachmentUrl || "",
      sizeInKB: 0,
      subject: values && values ? values.subject : "",
      from: localStorage.getItem("selectedBranchName"),

      import: false,
      to: to && to,
      cc: ccc ?? "",
      bcc: "",
      notClients: false,
      clientReply: false,
      clientReplyMail: 0,
      importMessageId: "",
      draft: false,
      emailType: 0,
      importText: "",
      // clientId: clientprofileid && clientprofileid,
      importedDate: new Date(),
      autoImport: false,
      isSent: true,
      clientEmailType: 0,
      timeStamp: new Date(),
    };
    if (fromClient) {
      draftData.clientId = clientprofileid;
    }
    if (fromPotentialClient) {
      draftData.potentialClientId = clientprofileid;
    }
    if (fromEmployer || fromPotentialEmployer) {
      draftData.employerId = clientprofileid;
    }

    const histOpt = {
      url: `v1/imap/${emailUrl}`,
    };
    histOpt.types = [
      "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
      "EMAIL_QUEUE_WITH_BLOB_FAILURE",
    ];

    apiRefresh
      .post(histOpt, draftData)
      .then((resp) => {
        formData.append("emailId", resp);
        const VisaOpt = {
          url: `v1/emailqueue/EmailQueueWithBlobUrl`,
        };
        VisaOpt.types = [
          "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
          "EMAIL_QUEUE_WITH_BLOB_FAILURE",
        ];

        apiRefresh.post(VisaOpt, formData).then((resp) => {
          setLoading(false);
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(sessionStorage.getItem("profileData"));
          let myData = {
            clientName: clientName || (profileData && profileData.fullName),
            logMessage:
              " Email with subject : " +
              values.subject +
              "\n" +
              '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
              '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
              '<div style="display: none">' +
              letterString +
              "</div>" +
              "<br />" +
              " created by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Document Checklist",
            invoiceId: "0",
          };
          activityData(myData);
          message.success("Successfully Sent!");

          handleCancel();
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onHandleChange = ({ fileList }) => {
    setTempFileList(fileList);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form
          onFinish={onFinish}
          form={form}
          className="width-100"
          name="main"
          {...layout}
        >
          <div className="border-box-checklist add-employer-para">
            <Form.Item
              name="to"
              label="To"
              colon={false}
              rules={[{ required: false }, { message: "Required" }]}
              labelAlign="left"
            >
              <Input
                placeholder="To"
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              labelAlign="left"
              style={{ marginTop: "12px" }}
              name="ccc"
              label="CC"
              required={false}
            >
              <Input
                placeholder="CC"
                value={ccc}
                onChange={(e) => setCcc(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              labelAlign="left"
              style={{ marginTop: "12px" }}
              name="subject"
              label="Subject"
              required={true}
              rules={[{ required: true }, { message: "Required" }]}
            >
              <Input />
            </Form.Item>
            <div className="margin-top-20 letter-froala">
              <FroalaEditorCom
                setLoading={(value) => setLoading(value)}
                model={letterString}
                onModelChange={(value) => setLetterString(value)}
              />
            </div>
            <div className="margin-top-20">
              <Row>
                <Col span={24}>
                  <div className="ts-upload-file-cont">
                    <Form.Item name="username" {...layoutUpload}>
                      <Upload
                        multiple
                        style={{ backgroundColor: "#fff" }}
                        listType="picture-card"
                        fileList={fileList}
                        beforeUpload={() => false}
                        onChange={onHandleChange}
                        className={"upload-technical-support"}
                      >
                        {fileList.length < 10 && (
                          <div className="import-file-button-sec">
                            <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                    {/* <Button
              // onClick={handleSubmit}
              className="float-right"
              htmlType="submit"
              type="primary"
            >
              Upload
            </Button> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <Row className="flex-end margin-top-12" gutter={10}>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  className="login-form-button save-btn button-blue"
                  htmlType="submit"
                >
                  SEND
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  onClick={() => handleCancel()}
                  type="primary"
                  className="login-form-button save-btn button-blue"
                >
                  CLOSE
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
};
export default DocumentChecklistForm;
