import React from "react";
import axios from "axios";
import * as types from "../store/Constants";
import history from "../services/history";

import { message } from "antd";
// const baseURL = "https://beta-api.ezymigrate.co.nz/";
const baseURL = "https://linuxapi-stage.ezymigrate.co.nz/";
let RefreshToken = true;
let SessionDeath = true;

let CallStack = [];
const onClose = () => {
  localStorage.clear();
  sessionStorage.clear();
  // history.location.push("/sign-up");
  window.location.reload();
};
const api = {
  authLogin: (options, params = null) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      axios({
        method: "POST",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  getLogout: (options) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,

        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  get: (options) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          refreshTokenData(err, 1, options, null);
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };
    return new Promise(promise);
  },
  getFile: (options) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };
    return new Promise(promise);
  },
  postWithFileRes: (options, params = null) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "POST",
        url: `${baseURL}${options.url}`,
        data: params,
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          refreshTokenData(err, 2, options, params);
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  post: (options, params = null) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "POST",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          refreshTokenData(err, 2, options, params);
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  put: (options, params = null) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "PUT",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          refreshTokenData(err, 3, options, params);
          console.log("err", err);
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  patch: (options, params = null) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "PATCH",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  delete: (options, params = null) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "DELETE",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          refreshTokenData(err, 4, options, params);
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
};

const apiRefresh = {
  authLogin: (options, params = null) => {
    const promise = (resolve, reject) => {
      axios({
        method: "POST",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
    };

    return new Promise(promise);
  },
  getLogout: (options) => {
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,

        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {})
        .catch((err) => {});
    };

    return new Promise(promise);
  },
  get: (options) => {
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    };
    return new Promise(promise);
  },
  getFile: (options) => {
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    };
    return new Promise(promise);
  },
  postWithFileRes: (options, params = null) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "POST",
        url: `${baseURL}${options.url}`,
        data: params,
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    };

    return new Promise(promise);
  },
  post: (options, params = null) => {
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "POST",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    };

    return new Promise(promise);
  },
  put: (options, params = null) => {
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "PUT",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    };

    return new Promise(promise);
  },
  patch: (options, params = null) => {
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "PATCH",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {})
        .catch((err) => {});
    };

    return new Promise(promise);
  },
  delete: (options, params = null) => {
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }
      axios({
        method: "DELETE",
        url: `${baseURL}${options.url}`,
        data: params,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    };

    return new Promise(promise);
  },
  getWithParams: (options, params = null) => {
    const promise = (resolve, reject) => {
      var token = localStorage.getItem("token");
      if (token == null) {
        token = localStorage.getItem("admintoken");
      }

      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        params: params,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    };
    return new Promise(promise);
  },
};

const refreshTokenData = async (err, apiType, opt, param) => {
  if (
    err &&
    err.response &&
    err.response.status === 401 &&
    err.response.data &&
    err.response.data.code == 2
  ) {
    if (RefreshToken) {
      RefreshToken = false;

      const options = {
        url: "v1/user/identity/RefreshToken",
      };

      options.types = [types.REFRESH_SUCCESS, types.REFRESH_FAILURE];
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken == null || refreshToken == "undefined") {
        localStorage.clear();
        sessionStorage.clear();
        history.location.push("/login");
        //window.location.reload();
      }
      var data = { refreshToken: refreshToken };

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        refreshToken: refreshToken,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${baseURL}${options.url}`, requestOptions)
        .then((result) => result.json())
        .then((res) => {
          console.log(res);

          const admintoken = localStorage.getItem("admintoken");
          if (admintoken) {
            localStorage.removeItem("admintoken");
            localStorage.setItem("admintoken", res.accessToken);
          } else {
            localStorage.removeItem("token");
            localStorage.setItem("token", res.accessToken);
          }
          localStorage.removeItem("refreshToken");
          localStorage.setItem("refreshToken", res.refreshToken);
          if (apiType == 1) {
            apiRefresh.get(opt);
          } else if (apiType == 2) {
            apiRefresh.post(opt, param);
          } else if (apiType == 3) {
            apiRefresh.put(opt, param);
          } else if (apiType == 4) {
            apiRefresh.delete(opt, param);
          }
          for (let i = 0; i < CallStack.length; i++) {
            if (CallStack[i].apiType == 1) {
              apiRefresh.get(CallStack[i].opt);
            } else if (CallStack[i].apiType == 2) {
              apiRefresh.post(CallStack[i].opt, CallStack[i].param);
            } else if (CallStack[i].apiType == 3) {
              apiRefresh.put(CallStack[i].opt, CallStack[i].param);
            } else if (CallStack[i].apiType == 4) {
              apiRefresh.delete(CallStack[i].opt, CallStack[i].param);
            }
          }
          RefreshToken = false;
          CallStack = [];
          window.location.reload();
        })
        .catch(async (err) => {
          err && localStorage.clear();
          sessionStorage.clear();
          history.location.push("/sign-up");
          // window.location.reload();
        });
    } else {
      var data = {
        apiType: apiType,
        opt: opt,
        param: param,
      };
      CallStack.push(data);
    }
  } else if (
    err &&
    err.response &&
    err.response.status === 401 &&
    err.response.data &&
    (err.response.data.code == 3 || err.response.data.code == 0) &&
    SessionDeath
  ) {
    SessionDeath = false;
    // message.error("Your Session has expired please login again", 5, onClose);
    localStorage.clear();
    sessionStorage.clear();
    // history.location.push("/sign-up");
    window.location.reload();
  }
  // else if(err && err.response && err.response.status === 404)
  // {
  //   message.info('No data found', 2)
  // }
  else if (err && err.response && err.response.status === 500) {
    if (opt.types[1] === "GET_MAIL_BOX_FAILURE") {
      message.error("Sorry! Imap not integrated!", 5);
    } else {
      message.error(
        "Oops! Something went wrong! Please contact our Support Team!",
        5
      );
    }
  }
};
export { api, apiRefresh, baseURL };
