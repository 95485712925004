// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
  DatePicker,
  Space,
} from "antd";

import OptFillQuestion from "./OptFillQuestion";
import AnswerField from "./AnswerField";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CascadedQuestionRow from "./CascadedQuestionRow";
import moment from "moment";

function FillQuestionRow(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail)
  const [value, setValue] = useState("");
  const [optionName, setOptionName] = useState("");
  const [question, setQuestion] = useState(Props.question);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [isFieldAdded, setFieldAdded] = useState(false);
  const [hideSection, setHideSection] = useState(false);
  const [isRunTime, setIsRunTime] = useState(false);
  const [questionIndData, setQuestionIndData] = useState([]);
  const [questionAded, setQuestionAdded] = useState(false);
  const [addMomDate, setAddMomDate] = useState(false);

  const { Option } = Select;

  var fieldAdded = false;
  var questAdded = false;

  var quesitonsDuplicate = [];

  var sectionId = "";

  // console.log(Props.field);

  const onChange = () => {};

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div className="row-style">
          {Props.questionData && Props.questionData.answerType === 0 ? (
            <div
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: Props.questionData.question,
              }}
            />
          ) : (
            <p className="cq-label-text questionnaire-label-width-38">
              {Props.questionData.isRequired
                ? Props.questionData.question + " *"
                : Props.questionData.question}
            </p>
          )}
          <Form.List
            name={[Props.question.name, "answers"]}
            key={[Props.question.fieldKey, "answers"]}
          >
            {(answers, { add, remove }) => {
              if (answers.length === 0 && !fieldAdded) {
                fieldAdded = true;

                Promise.all([add()]).then(
                  (res) => {
                    // all loaded
                    setQuestionAdded(true);
                  },
                  (err) => {
                    // one or more failed
                  }
                );

                // await Promise.all([add()]).then(res => {
                //
                // }).catch(err => {
                //
                // })
                // add();

                // if (Props.questionsLength > 3 || Props.sectionsLength > 2) {
                // try {
                //   add();
                // } catch (error) {
                //
                // }

                // } else {
                // setTimeout(() => {
                //   add();
                // }, 0.5);
                // }
                // fieldAdded = true;

                // add();
              }

              // if (answers.length > 0 && !questAdded) {
              //
              //   setQuestionAdded(true);
              // }
              return (
                <>
                  <div
                    // style={{
                    //   width:
                    //     Props.questionData.answerType == 0
                    //       ? 0
                    //       : Props.questionData.isMultiple
                    //       ? "55%"
                    //       : "60%",
                    // }}
                    className={
                      Props.questionData.answerType === 0
                        ? "questionnaire-input-width-0"
                        : Props.questionData.isMultiple
                        ? "questionnaire-input-width-55"
                        : "questionnaire-input-width-60"
                    }
                  >
                    {answers.map((answer, index) => {
                      // if (
                      //   !Props.isSaveClicked &&
                      //   Props.questionData &&
                      //   (Props.questionData.answerType == 2 ||
                      //     Props.questionData.answerType == 6) &&
                      //   Props.questionData.answers.length > 0
                      // ) {
                      //
                      //   var isValidDate = false;
                      //   var varType = typeof Props.questionData.answers[index]
                      //     .answer;
                      //   if (
                      //     Props.questionData.answers[index].answer &&
                      //     varType == "string"
                      //   ) {
                      //     isValidDate = moment(
                      //       Props.questionData.answers[index].answer,
                      //       "DD/MM/YYYY",
                      //       true
                      //     ).isValid();
                      //     if (isValidDate) {
                      //       var parts = Props.questionData.answers[
                      //         index
                      //       ].answer.split("/");
                      //       var day = parseInt(parts[0], 10);
                      //       var month = parseInt(parts[1], 10);
                      //       var year = parseInt(parts[2], 10);
                      //       Props.questionData.answers[
                      //         index
                      //       ].answer = `${year}/${month}/${day}`;
                      //     }
                      //   }
                      //   Props.questionData.answers[index].answer =
                      //     Props.questionData.answers[index].answer == "" ||
                      //     moment(
                      //       Props.questionData.answers[index].answer
                      //     ).format("YYYY-MM-DD") == "1900-01-01"
                      //       ? ""
                      //       : Props.questionData.answers[index].answer;

                      //   if (Props.questionData.answers[index].answer) {
                      //     Promise.all([
                      //       moment(Props.questionData.answers[index].answer),
                      //     ]).then(
                      //       (res) => {
                      //         console.log("moment resss", res);
                      //         Props.questionData.answers[index].answer = res[0];
                      //
                      //         setAddMomDate(true);
                      //         // all loaded
                      //       },
                      //       (err) => {
                      //         // one or more failed
                      //       }
                      //     );
                      //   } else {
                      //     setAddMomDate(true);
                      //   }
                      // } else {
                      //   setAddMomDate(true);
                      // }

                      return (
                        <Form.Item required={false} key={answer.key}>
                          <AnswerField
                            answer={answer}
                            questionData={Props.questionData}
                            remove={remove}
                            answerIndex={index}
                            selectedOption={(option, isCheckbox) => {
                              if (isCheckbox) {
                                setCheckboxOptions(option);
                              } else {
                                setOptionName(option);
                              }
                              setIsRunTime(true);
                            }}
                            countriesData={Props.countriesData}
                            visaTypeData={Props.visaTypeData}
                            onAddDocWithName={Props.onAddDocWithName}
                            state={Props.state}
                            onSaveFileList={Props.onSaveFileList}
                            chngLoadingState={Props.chngLoadingState}
                          />
                        </Form.Item>
                      );
                    })}
                  </div>

                  {/* {filledAnswers.length === 0 ? add() : null} */}
                  <Form.Item>
                    {Props.questionData.isMultiple && (
                      <div
                        className="cq-add-button"
                        onClick={() => add()}
                        style={{ marginLeft: 20 }}
                      >
                        <img src={Images.plusIcon} style={{ width: 20 }} />
                      </div>
                    )}
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </div>
      </div>
      <Form.List
        name={[Props.question.name, "questionOptions"]}
        key={[Props.question.fieldKey, "questionOptions"]}
      >
        {(questionOptions, { add, remove }) => {
          return (
            <div>
              {questionOptions.map((option, optInd) => (
                <div key={optInd}>
                  <Form.List
                    name={[option.name, "optionalQuestions"]}
                    key={[option.fieldKey, "optionalQuestions"]}
                  >
                    {(optionalQuestions, { add, remove }) => {
                      var checkOptionName = "";
                      if (
                        Props.questionData.isCascading &&
                        Props.questionData.answers.length > 0 &&
                        Props.questionData.answers[0].answer ==
                          Props.questionData.questionOptions[optInd].option &&
                        Props.questionData.questionOptions[optInd]
                          .optionalQuestions &&
                        Props.questionData.questionOptions[optInd]
                          .optionalQuestions.length > 0 &&
                        !isRunTime
                      ) {
                        checkOptionName =
                          Props.questionData.questionOptions[optInd].option;
                      } else {
                        if (checkboxOptions.length > 0) {
                          var findOption = checkboxOptions.find(
                            (obj) =>
                              obj ===
                              Props.questionData.questionOptions[optInd].option
                          );
                          if (findOption) {
                            checkOptionName = findOption;
                          }
                        } else {
                          checkOptionName = optionName;
                        }
                      }

                      var checkQuestions = "";

                      var hideQuestion = false;
                      if (
                        Props.questionData.questionOptions[optInd] &&
                        Props.questionData.questionOptions[optInd]
                          .optionalQuestions
                      ) {
                        for (
                          var a = 0;
                          a <
                          Props.questionData.questionOptions[optInd]
                            .optionalQuestions.length;
                          a++
                        ) {
                          for (
                            var b = 0;
                            b <
                            Props.questionData.questionOptions[optInd]
                              .optionalQuestions[a].questions.length;
                            b++
                          ) {
                            var findId = quesitonsDuplicate.find(
                              (obj) =>
                                obj.id ===
                                Props.questionData.questionOptions[optInd]
                                  .optionalQuestions[a].questions[b].id
                            );
                            if (!findId) {
                              quesitonsDuplicate.push(
                                Props.questionData.questionOptions[optInd]
                                  .optionalQuestions[a].questions[b]
                              );
                            }
                          }
                        }
                      }
                      // if (checkboxOptions.length > 1) {
                      //   for (var a = 0; a < checkboxOptions.length; a++) {
                      //     if (
                      //       checkboxOptions[a] ===
                      //       Props.questionData.questionOptions[optInd].option
                      //     ) {
                      //       if (
                      //         Props.questionData.questionOptions[optInd]
                      //           .optionalQuestions.length > 0
                      //       ) {
                      //         for (
                      //           var b = 0;
                      //           b <
                      //           Props.questionData.questionOptions[optInd]
                      //             .optionalQuestions.length;
                      //           b++
                      //         ) {
                      //           for (
                      //             var c = 0;
                      //             c <
                      //             Props.questionData.questionOptions[optInd]
                      //               .optionalQuestions[b].questions.length;
                      //             c++
                      //           ) {
                      //             var findId = quesitonsDuplicate.find(
                      //               (obj) =>
                      //                 obj.id ===
                      //                 Props.questionData.questionOptions[optInd]
                      //                   .optionalQuestions[b].questions[c].id
                      //             );
                      //             if (!findId) {
                      //               quesitonsDuplicate.push(
                      //                 Props.questionData.questionOptions[optInd]
                      //                   .optionalQuestions[b].questions[c]
                      //               );
                      //             }
                      //           }
                      //         }
                      //       }
                      //     }
                      //   }
                      // }

                      // for (
                      //   var a = 0;
                      //   a <
                      //   Props.questionData.questionOptions[optInd]
                      //     .optionalQuestions.length;
                      //   a++
                      // ) {
                      //   for (
                      //     var b = 0;
                      //     b <
                      //     Props.questionData.questionOptions[optInd]
                      //       .optionalQuestions[a].questions.length;
                      //     b++
                      //   ) {
                      //     var findId = quesitonsDuplicate.find(
                      //       (obj) =>
                      //         obj.id ===
                      //         Props.questionData.questionOptions[optInd]
                      //           .optionalQuestions[a].questions[b].id
                      //     );
                      //     if (!findId) {
                      //       quesitonsDuplicate.push(
                      //         Props.questionData.questionOptions[optInd]
                      //           .optionalQuestions[a].questions[b]
                      //       );
                      //     }
                      //   }
                      // }
                      // var hideQuestion = false;
                      if (checkboxOptions.length > 1) {
                        for (var z = 0; z < checkboxOptions.length; z++) {
                          if (
                            checkboxOptions[z] ===
                            Props.questionData.questionOptions[optInd].option
                          ) {
                            for (
                              var a = 0;
                              a <
                              Props.questionData.questionOptions[optInd]
                                .optionalQuestions.length;
                              a++
                            ) {
                              for (
                                var b = 0;
                                b <
                                Props.questionData.questionOptions[optInd]
                                  .optionalQuestions[a].questions.length;
                                b++
                              ) {
                                var findMultiple = quesitonsDuplicate.filter(
                                  (obj) =>
                                    obj.question ===
                                    Props.questionData.questionOptions[optInd]
                                      .optionalQuestions[a].questions[b]
                                      .question
                                );
                                if (findMultiple.length > 0) {
                                  hideQuestion = true;
                                  sectionId = findMultiple[0].sectionId;
                                }
                              }
                            }
                          }
                        }
                      }
                      return (
                        <div>
                          {checkOptionName ===
                            Props.questionData.questionOptions[optInd].option &&
                            optionalQuestions.map((optQuestion, optqInd) => {
                              return (
                                <>
                                  {(!hideQuestion ||
                                    (checkboxOptions.length > 0 &&
                                      sectionId ===
                                        Props.questionData.questionOptions[
                                          optInd
                                        ].optionalQuestions[optqInd].id)) && (
                                    <div>
                                      <div
                                        className="section-heading-row"
                                        style={{
                                          paddingBottom: 10,
                                        }}
                                      >
                                        <div>
                                          <span className="heading-text">
                                            {(Props.questionData
                                              .questionOptions[optInd]
                                              .optionalQuestions[optqInd] &&
                                              Props.questionData
                                                .questionOptions[optInd]
                                                .optionalQuestions[optqInd]
                                                .name) ||
                                              ""}
                                          </span>
                                          {optqInd == 0 &&
                                          Props.questionData.questionOptions[
                                            optInd
                                          ].optionalQuestions[optqInd] &&
                                          Props.questionData.questionOptions[
                                            optInd
                                          ].optionalQuestions[optqInd]
                                            .allowMultiple ? (
                                            <div
                                              className="cq-add-button"
                                              style={{ marginLeft: 20 }}
                                              onClick={() => {
                                                add(
                                                  Props.questionData
                                                    .questionOptions[optInd]
                                                    .optionalQuestions[optqInd]
                                                );
                                                Props.questionData.questionOptions[
                                                  optInd
                                                ].optionalQuestions.push(
                                                  Props.questionData
                                                    .questionOptions[optInd]
                                                    .optionalQuestions[optqInd]
                                                );
                                              }}
                                            >
                                              <img
                                                src={Images.plusIcon}
                                                style={{ width: 20 }}
                                              />
                                            </div>
                                          ) : (
                                            optqInd != 0 &&
                                            Props.questionData.questionOptions[
                                              optInd
                                            ].optionalQuestions[optqInd] &&
                                            Props.questionData.questionOptions[
                                              optInd
                                            ].optionalQuestions[optqInd]
                                              .allowMultiple &&
                                            Props.questionData.questionOptions[
                                              optInd
                                            ].optionalQuestions[optqInd].id !=
                                              Props.questionData
                                                .questionOptions[optInd]
                                                .optionalQuestions[optqInd]
                                                .id && (
                                              <div
                                                className="cq-add-button"
                                                style={{ marginLeft: 20 }}
                                                onClick={() => {
                                                  add(
                                                    Props.questionData
                                                      .questionOptions[optInd]
                                                      .optionalQuestions[
                                                      optqInd
                                                    ]
                                                  );
                                                  Props.questionData.questionOptions[
                                                    optInd
                                                  ].optionalQuestions.push(
                                                    Props.questionData
                                                      .questionOptions[optInd]
                                                      .optionalQuestions[
                                                      optqInd
                                                    ]
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={Images.plusIcon}
                                                  style={{ width: 20 }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>

                                        {hideSection ? (
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              setHideSection(false)
                                            }
                                          >
                                            <span
                                              className="heading-text"
                                              style={{
                                                fontSize: 11,
                                              }}
                                            >
                                              SHOW
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setHideSection(true)}
                                          >
                                            <span
                                              className="heading-text"
                                              style={{
                                                fontSize: 11,
                                              }}
                                            >
                                              HIDE
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      {!hideSection && (
                                        <div
                                          className={
                                            optQuestion &&
                                            "cq-optional-section-cont"
                                          }
                                        >
                                          <Form.List
                                            name={[
                                              optQuestion.name,
                                              "questions",
                                            ]}
                                            key={[
                                              optQuestion.fieldKey,
                                              "questions",
                                            ]}
                                          >
                                            {(questions, { add, remove }) => {
                                              return (
                                                <div>
                                                  {questions.map(
                                                    (optQuestion, quesInd) => {
                                                      return (
                                                        <div
                                                          key={quesInd}
                                                          style={{
                                                            marginTop:
                                                              quesInd === 0
                                                                ? 0
                                                                : 20,
                                                          }}
                                                        >
                                                          <Form.Item
                                                            required={false}
                                                            key={question.key}
                                                          >
                                                            <CascadedQuestionRow
                                                              question={
                                                                optQuestion
                                                              }
                                                              questionData={
                                                                Props
                                                                  .questionData
                                                                  .questionOptions[
                                                                  optInd
                                                                ]
                                                                  .optionalQuestions[
                                                                  optqInd
                                                                ] &&
                                                                Props
                                                                  .questionData
                                                                  .questionOptions[
                                                                  optInd
                                                                ]
                                                                  .optionalQuestions[
                                                                  optqInd
                                                                ].questions[
                                                                  quesInd
                                                                ]
                                                              }
                                                              countriesData={
                                                                Props.countriesData
                                                              }
                                                              visaTypeData={
                                                                Props.visaTypeData
                                                              }
                                                              questionsLength={
                                                                Props
                                                                  .questionData
                                                                  .questionOptions[
                                                                  optInd
                                                                ]
                                                                  .optionalQuestions[
                                                                  optqInd
                                                                ] &&
                                                                Props
                                                                  .questionData
                                                                  .questionOptions[
                                                                  optInd
                                                                ]
                                                                  .optionalQuestions[
                                                                  optqInd
                                                                ].questions
                                                                  .length
                                                              }
                                                              sectionsLength={
                                                                Props
                                                                  .questionData
                                                                  .questionOptions[
                                                                  optInd
                                                                ]
                                                                  .optionalQuestions
                                                                  .length
                                                              }
                                                              onAddDocWithName={
                                                                Props.onAddDocWithName
                                                              }
                                                              state={
                                                                Props.state
                                                              }
                                                              onSaveFileList={
                                                                Props.onSaveFileList
                                                              }
                                                              chngLoadingState={
                                                                Props.chngLoadingState
                                                              }
                                                            />
                                                          </Form.Item>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              );
                                            }}
                                          </Form.List>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      );
                    }}
                  </Form.List>
                </div>
              ))}
            </div>
          );
        }}
      </Form.List>
      {/*{Props.questionData.questionOptions && Props.questionData.questionOptions.length > 0 && Props.questionData.questionOptions.map((option, optInd) => {
        return (
          <div key={optInd}>
          {optionName === option.option && option.optionalQuestions && option.optionalQuestions.length > 0 &&
            option.optionalQuestions.map((optionalQuestion, optqInd) => {
              return (
                <div key={optqInd} className={optionalQuestion.questions.length > 0 && "cq-optional-cont"}>
                {
                  optionalQuestion.questions && optionalQuestion.questions.length > 0 && optionalQuestion.questions.map((optQuestion, ind) => {
                     
                    return (
                      <div
                        key={ind}
                        style={{
                          marginTop: 15
                        }}
                      >
                        <OptFillQuestion question={optQuestion} />
                      </div>
                    )
                  })
                }
                </div>
                )
            })}
          </div>
          )

      })}*/}
    </div>
  );
}

export default FillQuestionRow;
