import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Select,
  Checkbox,
  Upload,
  Modal,
  Spin,
  Radio,
  Table,
} from "antd";
import {
  CloseCircleOutlined,
  PlusOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import CreateEmailDocuments from "./CreateEmailDocuments";
import CreateEmailEditors from "./CreateEmailEditors";
import moment from "moment";
import { visaForms } from "../../../../../VisaForm/NZForms";
import { Images } from "../../../../../Themes";
// import { ADD_FAMILY_LINK_SUCCESS } from "../../../store/Constants";
import * as types from "../../../../../store/Constants";
import { apiRefresh } from "../../../../../services/api";
import { saveAs } from "file-saver";
import FroalaEditorCom from "../../../../../Containers/FroalaEditorCom";

const { Option } = Select;

function onLetterChange(value) {
  console.log(`selected ${value}`);
}
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

function onSearch(val) {
  console.log("search:", val);
}

const DocumentChecklistForm = ({
  onSendEmailLink,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddEmployerEmail,

  onAddDraftClient,

  onGetClientProfile,
  clientProfileData,

  onUploadAvatar,

  onAddEmailDocument,
  emailDocumentRes,

  setHandleKey,
  onGetClientFamilyEmails,
  clientEmailRes,

  onGetEmployerDocument,
  employerDocumentRes,

  onGetImapSettingss,
  imapSettingRes,

  onGetSignature,
  signatureRes,

  onGetPdf,

  onGetLink,

  onGetDocuments,
  documentRes,

  inHistoryPopup,

  emailContent,
  forward,
  reply,
  replyAll,
  isDraft,
  onGetDocumentDownload,
  handleCancelPopup,

  onDeleteClientEmail,

  userDataEmp,

  onGetEmployerHistory,
  onAddPotentialEmail,
  onGetPClientHistory,
  onDeleteCreateEmail,
  emailContentData,
}) => {
  const [to, setTo] = useState("");
  const [ccc, setCcc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [lettersData, setLettersData] = useState([]);
  const [contractsData, setContractsData] = useState([]);
  const [letterString, setLetterString] = useState(emailContentData);
  const [isSendCopy, setIsSendCopy] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, SetPreviewVisible] = useState(false);
  const [previewImage, SetPreviewImage] = useState("");
  const [previewTitle, SetPreviewTitle] = useState("");
  const [emailFile, SetEmailFile] = useState([]);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState("");
  const [showDocPopup, setShowDocPopup] = useState(false);
  const [docArray, setDocArray] = useState([]);
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [showVisaFormPopup, setShowVisaFormPopup] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState({
    attachmentName: "",
    attachmentUrl: "",
  });
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [hostName, setHostName] = useState("");
  const [showEditor, setShowEditor] = useState(true);
  const [showComDocPopup, setShowComDocPopup] = useState(false);
  const [selectedRowKeys2, setSelectedRowKeys2] = useState([]);
  const [commonDocArray, setCommonDocArray] = useState([]);
  const [visaFormArray, setVisaFormArray] = useState([]);
  const [selectedRowKeys3, setSelectedRowKeys3] = useState([]);
  const [showAttachmentsData, setShowAttachmentsData] = useState([]);
  const [contractValue, setContractValue] = useState("");
  const [templateValue, setTemplateValue] = useState("");
  const [emailsList, setEmailsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bol, setBol] = useState(false);
  const [tempFileList, setTempFileList] = useState([]);
  const [formData, setFormData] = useState();
  const [size, setSize] = useState([0]);
  const [branchEmailAddresses, setBranchEmailAddresses] = useState([]);
  const [tempSize, setTempSize] = useState([0]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter === 2) {
      let attachmentUrl = attachmentsData.attachmentUrl;
      let attachmentName = attachmentsData.attachmentName;
      setFileList(tempFileList);
      onAddEmailDocument(formData)
        .then((res) => {
          for (var i = 0; i < res.payload.length; i++) {
            let pdfData = {
              url: res.payload[i].fileUrl,
              name: res.payload[i].fileName,
              type: res.payload[i].contentType,
            };
            setShowAttachmentsData((prevState) => [...prevState, pdfData]);
            let semiColon = "";
            if (attachmentName !== "") {
              semiColon = ";";
            }
            attachmentName += semiColon + res.payload[i].fileName;
            attachmentUrl += semiColon + res.payload[i].fileUrl;
          }
          setAttachmentsData({
            attachmentUrl: attachmentUrl,
            attachmentName: attachmentName,
          });
          SetEmailFile(res && res.payload);
          setLoading(false);
          handleCancel();
          message.success("Successfully Added!");
          setSize(tempSize);
        })
        .catch((err) => {
          if (err.payload && err.payload.response == undefined) {
            message.error("Max upload file size reached! Uploading is failed!");
          }
          setLoading(false);
        });
    } else {
    }
  }, [bol]);

  useEffect(() => {
    if (tempFileList && tempFileList.length === 1) {
      let formData = new FormData();
      var fs = require("fs");
      setLoading(true);
      formData.append("BranchId", localStorage.getItem("selectedBranchId"));
      let _size = [...size];

      if (tempFileList && tempFileList.length > 0) {
        for (let i = 0; i < tempFileList.length; i++) {
          formData.append("files", tempFileList[i].originFileObj);
          _size[0] = _size[0] + tempFileList[i].originFileObj.size;
        }
      }

      if (_size <= 15000000) {
        setSize(_size);
        let attachmentUrl = attachmentsData.attachmentUrl;
        let attachmentName = attachmentsData.attachmentName;
        setFileList(tempFileList);
        onAddEmailDocument(formData)
          .then((res) => {
            for (var i = 0; i < res.payload.length; i++) {
              let pdfData = {
                url: res.payload[i].fileUrl,
                name: res.payload[i].fileName,
                type: res.payload[i].contentType,
              };
              setShowAttachmentsData((prevState) => [...prevState, pdfData]);
              let semiColon = "";
              if (attachmentName !== "") {
                semiColon = ";";
              }
              attachmentName += semiColon + res.payload[i].fileName;
              attachmentUrl += semiColon + res.payload[i].fileUrl;
            }
            setAttachmentsData({
              attachmentUrl: attachmentUrl,
              attachmentName: attachmentName,
            });
            SetEmailFile(res && res.payload);
            setLoading(false);
            handleCancel();
            message.success("Successfully Added!");
          })
          .catch((err) => {
            if (err.payload && err.payload.response == undefined) {
              message.error(
                "Max upload file size reached! Uploading is failed!"
              );
            }
            setLoading(false);
          });
      } else {
        message.error("Max upload file size reached! Uploading is failed!");
        setLoading(false);
      }
    } else {
      let formData = new FormData();
      var fs = require("fs");
      setLoading(true);
      formData.append("BranchId", localStorage.getItem("selectedBranchId"));
      let _size = [...size];

      let tempSize = 0;
      if (tempFileList && tempFileList.length > 0) {
        for (let i = 0; i < tempFileList.length; i++) {
          formData.append("files", tempFileList[i].originFileObj);
          tempSize = tempSize + tempFileList[i].originFileObj.size;
        }
      }
      _size[0] = _size[0] + tempSize;
      setFormData(formData);
      if (_size <= 15000000) {
        setCounter(counter + 1);
        setBol(!bol);
        setTempSize(_size);
      } else {
        message.error("Max upload file size reached! Uploading is failed!");
        setLoading(false);
      }
    }
  }, [tempFileList]);

  useEffect(() => {
    const branchEmailsOpt = {
      url: `v1/user/Branch/UserBranchEmails`,
    };

    branchEmailsOpt.types = [
      "GET_BRANCH_EMAILS_SUCCESS",
      "GET_BRANCH_EMAILS_FAILURE",
    ];
    apiRefresh
      .get(branchEmailsOpt)
      .then((res) => {
        setBranchEmailAddresses(res);
      })
      .catch((err) => {
        setBranchEmailAddresses([]);
      });
    var data = new Object();
    data.id = userDataEmp.id;
    data.docType = -1;
    data.CaseId = 0;
    data.IsVisa = false;
    onGetEmployerDocument(data);
  }, []);

  useEffect(() => {
    if (userDataEmp && userDataEmp.email) {
      setTo(userDataEmp.email);
      form.setFieldsValue({
        to: userDataEmp.email,
      });
    }
  }, [userDataEmp]);

  useEffect(() => {
    setLoading(true);

    // if (!emailContent) {
    onGetSignature()
      .then((res) => {
        setLoading(false);
        if (forward && emailContent) {
          var text =
            letterString +
            res.payload.signatureText +
            "<br/>" +
            "<p>---------- Forwarded message ---------</p><br/><p>From: " +
            emailContent.from +
            "</p><p>To: " +
            emailContent.to +
            "</p><p>Subject: " +
            emailContent.subject +
            emailContent.emailMessage;
          setLetterString(text);
        }
        if (replyAll || reply || !emailContent) {
          var emailReply = emailContent
            ? "<br/><p>From: " +
              emailContent.from +
              "</p><p>To: " +
              emailContent.to +
              "</p><p>Subject: " +
              emailContent.subject +
              emailContent.emailMessage
            : "";
          setLetterString(
            letterString + res.payload.signatureText + "<br/>" + emailReply
          );
        }
      })
      .catch((err) => {
        setLoading(false);
      });

    onGetDocuments().then((res) => {});
    onGetLetterTemplates()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => setLoading(false));
    // onGetImapSettingss().then((res) => {
    //   if (res.payload.useImap) {
    //     localStorage.setItem("imapEmail", res.payload.exportEmail);
    //   }
    // });
    // imapSettingRes = { imapSettingRes };
    let clientprofileid = sessionStorage.getItem("clientprofileid");
  }, [onGetLetterTemplates, setLoading]);

  useEffect(() => {
    if (emailContent) {
      console.log(form);

      if (emailContent.emailAttachment.length > 0 && (forward || isDraft)) {
        setShowAttachmentsData([]);
        let attachmentUrl = "";
        let attachmentName = "";
        for (var i = 0; i < emailContent.emailAttachment.length; i++) {
          let pdfData = {
            url: emailContent.emailAttachment[i].blobURL,
            name: emailContent.emailAttachment[i].name,
            type: emailContent.emailAttachment[i].type,
          };
          setShowAttachmentsData((prevState) => [...prevState, pdfData]);
          let semiColon = "";
          if (attachmentName !== "") {
            semiColon = ";";
          }
          attachmentName += semiColon + emailContent.emailAttachment[i].name;
          attachmentUrl += semiColon + emailContent.emailAttachment[i].blobURL;
        }
        setAttachmentsData({
          attachmentUrl: attachmentUrl,
          attachmentName: attachmentName,
        });
      }
    }
    if (isDraft) {
      form.setFieldsValue({
        to: emailContent.to,
        ccc: emailContent.cc,
        bcc: emailContent.bcc,
        subject: emailContent.subject,
      });
      setTo(emailContent.to);
      setCcc(emailContent.cc);
      setBcc(emailContent.bcc);
      setSubject(emailContent.subject);
      setLetterString(emailContent.emailMessage);
      if (emailContent.draftContract) {
        var dContract = JSON.parse(emailContent.draftContract);
        setContractsData(dContract);
      }
    }
    if (forward) {
      var emailReply = emailContent
        ? "<p>---------- Forwarded message ---------</p><br/><p>From: " +
          emailContent.from +
          "</p><p>To: " +
          emailContent.to +
          "</p><p>Subject: " +
          emailContent.subject +
          emailContent.emailMessage
        : "";
      setLetterString(
        "<p>Dear " + userDataEmp.name + "</p>" + "<br/>" + emailReply
      );
      setSubject(emailContent.subject);
      // setLetterString(emailContent.emailMessage);
      form.setFieldsValue({
        subject: "Fwd: " + emailContent.subject,
      });
    }

    if (reply) {
      form.setFieldsValue({
        to: emailContent.from,
        subject: "Re: " + emailContent.subject,
      });

      setTo(emailContent.from);
      setSubject(emailContent.subject);
    }
    if (replyAll) {
      setTo(emailContent.from);
      setCcc(emailContent.cc);
      setBcc(emailContent.bcc);
      setSubject(emailContent.subject);
      form.setFieldsValue({
        to: emailContent.from,
        ccc: emailContent.cc,
        bcc: emailContent.bcc,
        subject: "Re: " + emailContent.subject,
      });
    }
  }, [emailContent, forward, reply, replyAll, isDraft]);

  useEffect(() => {
    if (
      employerDocumentRes &&
      employerDocumentRes.items &&
      employerDocumentRes.items.length > 0
    ) {
      employerDocumentRes.items.map((data, index) => {
        if (employerDocumentRes) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });
      setDocArray(employerDocumentRes.items);
    }
    if (documentRes && documentRes.items && documentRes.items.length > 0) {
      documentRes.items.map((data, index) => {
        if (documentRes) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });
      var findDocument = documentRes.items.filter(
        (obj) => obj.companyDocumentTypeId === 1
      );
      setCommonDocArray(findDocument);
    }
    if (visaForms.length > 0) {
      visaForms.map((data, index) => {
        if (visaForms) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });
      setVisaFormArray(visaForms);
    }
  }, [employerDocumentRes, documentRes, visaForms]);

  const onContractChange = (id, selection) => {
    var subStr = subject;
    var strSepComa = ",";
    const getContractOpt = {
      url: `v1/template/${id}`,
    };

    getContractOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh.get(getContractOpt).then((response) => {
      if (selection == "contract") {
        setContractValue(id);
      } else {
        setTemplateValue(id);
      }
      let checkId = null;
      if (selectedContracts.length > 0) {
        checkId = selectedContracts.find((obj) => obj.id == id);
      }
      if (!checkId && selection == "letter") {
        let dataId = {
          id,
        };
        setSelectedContracts((prevState) => [...prevState, dataId]);

        if (response) {
          if (subStr) {
            subStr += strSepComa + " " + response.name;
          } else {
            subStr = response.name;
          }
          setSubject(subStr);
          form.setFieldsValue({
            subject: subStr,
          });
          setLoading(true);
          var paramArray = [];
          var param1 = new Object();
          param1.key = "ClientId";
          param1.value = userDataEmp && userDataEmp.id;
          paramArray.push(param1);
          var param2 = new Object();
          param2.key = "UserId";
          param2.value = localStorage.getItem("userId");
          paramArray.push(param2);
          var data = new Object();
          data.templateName = response.content
            ? response.content.replace(
                "@CurrentDate",
                moment(new Date()).format("DD/MM/YYYY")
              )
            : "";
          data.parameters = paramArray;

          const docTypeOpt = {
            url: `v1/HtmlTemplate/SetAnyTemplate`,
          };

          docTypeOpt.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];
          apiRefresh
            .post(docTypeOpt, data)
            .then((res) => {
              setLoading(false);

              let data = {
                id: id,
                content: res,
                isAttachment: false,
                header: response.header,
                name: response.name,
              };
              // setTimeout(() => {
              setContractsData((prevState) => [data, ...prevState]);
              // }, 500);
            })
            .catch((err) => {
              setLoading(false);
              message.error("Failed!");
            });
          const docTypeOpt1 = {
            url: `v1/template/Attachments/All/` + id,
          };
          docTypeOpt1.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];

          apiRefresh
            .get(docTypeOpt1)
            .then((resp) => {
              setLoading(false);
              let attachmentUrl = attachmentsData.attachmentUrl;
              let attachmentName = attachmentsData.attachmentName;
              if (resp.items && resp.items.length > 0) {
                for (var i = 0; i < resp.items.length; i++) {
                  let nameExtension = resp.items[i].name.split(".");
                  let pdfData = {
                    url: resp.items[i].url,
                    name: resp.items[i].name,
                    type: nameExtension[nameExtension.length - 1],
                  };
                  setShowAttachmentsData((prevState) => [
                    ...prevState,
                    pdfData,
                  ]);
                  let semiColon = "";
                  if (attachmentName !== "") {
                    semiColon = ";";
                  }
                  attachmentName += semiColon + resp.items[i].name;
                  attachmentUrl += semiColon + resp.items[i].url;
                }
                setAttachmentsData({
                  attachmentUrl: attachmentUrl,
                  attachmentName: attachmentName,
                });
              }
              // onAddAttachment(true, data, attachmentUrl, attachmentName);
            })
            .catch((err) => {
              setLoading(false);
            });
        }

        // let template = lettersData.find((obj) => obj.id == id);
        //
        // if (template) {
        //   let data = {
        //     id: id,
        //     content: template.content,
        //     isAttachment: true,
        //     header: template.header,
        //   };
        //
        //   setTimeout(() => {
        //     setContractsData((prevState) => [data, ...prevState]);
        //   }, 500);
        //
        //   onAddAttachment(true, data);
        // }
      } else if (!checkId && selection != "letter") {
        let dataId = {
          id,
        };
        setSelectedContracts((prevState) => [...prevState, dataId]);

        if (response) {
          if (subStr) {
            subStr += strSepComa + " " + response.name;
          } else {
            subStr = response.name;
          }
          setSubject(subStr);
          form.setFieldsValue({
            subject: subStr,
          });
          setLoading(true);
          let clientprofileid = sessionStorage.getItem("clientprofileid");

          var contractData = null;
          var paramArray = [];
          var param1 = new Object();
          param1.key = "ClientId";
          param1.value = userDataEmp && userDataEmp.id;
          paramArray.push(param1);
          var param2 = new Object();
          param2.key = "UserId";
          param2.value = localStorage.getItem("userId");
          paramArray.push(param2);
          var data = new Object();
          data.templateName = response.content
            ? response.content.replace(
                "@CurrentDate",
                moment(new Date()).format("DD/MM/YYYY")
              )
            : "";
          data.parameters = paramArray;
          const docTypeOpt = {
            url: `v1/HtmlTemplate/SetAnyTemplate`,
          };

          docTypeOpt.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];
          apiRefresh
            .post(docTypeOpt, data)
            .then((res) => {
              contractData = {
                id: id,
                content: res,
                isAttachment: true,
                header: response.header,
                name: response.name,
              };
              // setTimeout(() => {
              setContractsData((prevState) => [contractData, ...prevState]);
              // }, 500);
              const docTypeOpt1 = {
                url: `v1/template/Attachments/All/` + id,
              };
              docTypeOpt1.types = [
                types.GET_DOCUMENT_TYPE_SUCCESS,
                types.GET_DOCUMENT_TYPE_FAILURE,
              ];

              apiRefresh
                .get(docTypeOpt1)
                .then((resp) => {
                  setLoading(false);
                  let attachmentUrl = attachmentsData.attachmentUrl;
                  let attachmentName = attachmentsData.attachmentName;
                  if (resp.items && resp.items.length > 0) {
                    for (var i = 0; i < resp.items.length; i++) {
                      let nameExtension = resp.items[i].name.split(".");
                      let pdfData = {
                        url: resp.items[i].url,
                        name: resp.items[i].name,
                        type: nameExtension[nameExtension.length - 1],
                      };

                      setShowAttachmentsData((prevState) => [
                        ...prevState,
                        pdfData,
                      ]);
                      let semiColon = "";
                      if (attachmentName !== "") {
                        semiColon = ";";
                      }
                      attachmentName += semiColon + resp.items[i].name;
                      attachmentUrl += semiColon + resp.items[i].url;
                    }
                    setAttachmentsData({
                      attachmentUrl: attachmentUrl,
                      attachmentName: attachmentName,
                    });
                  }
                  contractDataAttachment(true, contractData);
                })
                .catch((err) => {
                  setLoading(false);
                  contractDataAttachment(true, contractData);
                });
            })
            .catch((err) => {
              setLoading(false);
              message.error("Failed!");
            });
        }
      } else {
        message.warning("Contract already selected!");
      }
    });
  };

  useEffect(() => {
    letterTempaltesFilter(LetterTemplatesRes);
  }, [LetterTemplatesRes]);

  useEffect(() => {
    let emails = [];
    if (clientEmailRes) {
      if (clientEmailRes.items.length > 0) {
        for (var i = 0; i < clientEmailRes.items.length; i++) {
          let email = {
            name: clientEmailRes.items[i].emailType,
            address: clientEmailRes.items[i].address,
            memberType: clientEmailRes.items[i].memberType,
          };
          emails.push(email);
        }
      }
      if (
        clientEmailRes.processingPersonEmails &&
        clientEmailRes.processingPersonEmails.length > 0
      ) {
        for (var i = 0; i < clientEmailRes.processingPersonEmails.length; i++) {
          let email = {
            name: clientEmailRes.processingPersonEmails[i].name,
            address: clientEmailRes.processingPersonEmails[i].email,
            memberType: "Processing Person",
          };
          emails.push(email);
        }
      }
      if (
        clientEmailRes.supplierEmails &&
        clientEmailRes.supplierEmails.length > 0
      ) {
        for (var i = 0; i < clientEmailRes.supplierEmails.length; i++) {
          let email = {
            name: clientEmailRes.supplierEmails[i].name,
            address: clientEmailRes.supplierEmails[i].email,
            memberType: "Employer",
          };
          emails.push(email);
        }
      }
      if (clientEmailRes.agentEmails && clientEmailRes.agentEmails.length > 0) {
        for (var i = 0; i < clientEmailRes.agentEmails.length; i++) {
          let email = {
            name: clientEmailRes.agentEmails[i].name,
            address: clientEmailRes.agentEmails[i].email,
            memberType: "Agent",
          };
          emails.push(email);
        }
      }
    }
    setEmailsList(emails);
  }, [clientEmailRes]);

  const letterTempaltesFilter = (letterTemplatesRes) => {
    let filterList = [];
    let filterListLetters = [];
    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "CONTRACT") {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });

      setDataSource(filterList);
    }
    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "LETTER") {
          data.index = index;
          data.key = `${index + 1}`;
          filterListLetters.push(data);
        }
      });

      setLettersData(filterListLetters);
    }
  };
  // const clientEmail = () => {};

  const [form] = Form.useForm();

  const handleCancel = () => SetPreviewVisible(false);

  const onHandleChange = ({ fileList }) => {
    setTempFileList(fileList);
  };

  const onFinish = (values) => {
    setLoading(true);
    var attachmentName = "";
    var attachmentUrl = "";
    var countContractIndex = 0;
    var contractAttachments = [];
    let attachmentString = {
      attachmentName: "",
      attachmentUrl: "",
    };
    var isContractAttachment = false;

    if (typeof values.to !== "undefined" && values.to.includes(",")) {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    if (typeof values.ccc !== "undefined" && values.ccc.includes(",")) {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    if (typeof values.bcc !== "undefined" && values.bcc.includes(",")) {
      return message.info("Emails should be seprated by a semicolon (;)");
    }

    var contentData = showEditor && letterString ? letterString + "<br/>" : "";
    if (contractsData.length > 0) {
      for (var i = contractsData.length - 1; i > -1; i--) {
        if (!contractsData[i].isAttachment) {
          contentData += contractsData[i].content + "<br/>";
          countContractIndex++;
          if (
            !isContractAttachment &&
            countContractIndex == contractsData.length
          ) {
            callSendApi(
              values,
              contentData,
              contractAttachments,
              attachmentString
            );
          }
        } else {
          isContractAttachment = true;
          var content = contractsData[i].content;
          var formValue = new FormData();
          formValue.append(`Html`, content);
          formValue.append(
            `BranchId`,
            localStorage.getItem("selectedBranchId")
          );
          formValue.append(`FileTitle`, contractsData[i].name + ".pdf");
          onGetPdf(formValue)
            .then((res) => {
              countContractIndex++;
              setLoading(false);
              let pdfData = {
                url: res.payload.uri,
                name: res.payload.fileName,
                type: "application/pdf",
              };
              contractAttachments.push(pdfData);
              // setShowAttachmentsData((prevState) => [...prevState, pdfData]);
              let semiColon = "";
              if (attachmentName !== "") {
                semiColon = ";";
              }
              attachmentName += semiColon + res.payload.fileName;
              attachmentUrl += semiColon + res.payload.uri;
              // setAttachmentsData({
              //   attachmentUrl: attachmentUrl,
              //   attachmentName: attachmentName,
              // });
              attachmentString = {
                attachmentUrl: attachmentUrl,
                attachmentName: attachmentName,
              };

              if (countContractIndex == contractsData.length) {
                callSendApi(
                  values,
                  contentData,
                  contractAttachments,
                  attachmentString
                );
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      }
    } else {
      callSendApi(values, contentData, contractAttachments, attachmentString);
    }
  };

  const callSendApi = (
    values,
    contentData,
    contractAttachments,
    attachmentString
  ) => {
    setLoading(true);
    console.log("Received values of form:", values);
    let selectedBranchName = localStorage.getItem("selectedBranchName");
    let count = 0;
    const formData = new FormData();
    formData.append(`Recipients[0].name`, to && to);
    formData.append(`Recipients[0].type`, "To");
    if (ccc) {
      count++;
      formData.append(`Recipients[${count}].name`, ccc && ccc);
      formData.append(`Recipients[${count}].type`, "CC");
    }
    if (bcc) {
      count++;
      formData.append(`Recipients[${count}].name`, bcc);
      formData.append(`Recipients[${count}].type`, "Bcc");
    }
    let cc = "";
    if (isSendCopy === true) {
      count++;
      let userEmail = localStorage.getItem("userEmail");
      formData.append(`Recipients[${count}].name`, userEmail);
      formData.append(`Recipients[${count}].type`, "CC");
      if (ccc != "") {
        cc += ";" + userEmail;
      } else {
        cc = userEmail;
      }
      setCcc(cc);
    }

    formData.append("Subject", values && values.subject);
    formData.append("Message", contentData);
    formData.append("From", selectedBranchName);
    formData.append("Priority", "10");
    formData.append("FrequencyCode", "employer");
    formData.append("ModuleId", "10");
    var mergeArray = showAttachmentsData.concat(contractAttachments);

    if (mergeArray.length > 0) {
      for (var i = 0; i < mergeArray.length; i++) {
        formData.append(`Attachments[${i}].name`, mergeArray[i].name);
        formData.append(`Attachments[${i}].type`, mergeArray[i].type);
        formData.append(`Attachments[${i}].blobURL`, mergeArray[i].url);
      }
    } else {
      formData.append(`Attachments[0].name`, "");
      formData.append(`Attachments[0].type`, "");
      formData.append(`Attachments[0].blobURL`, "");
    }

    var semiColon = "";

    if (attachmentString.attachmentName != "") {
      semiColon = ";";
    }

    var dContracts = "";
    if (contractsData.length > 0) {
      dContracts = JSON.stringify(contractsData);
    }

    const draftData = {
      id: 0,
      emailMessage: letterString ? letterString : "",
      draftContract: "",
      draftLetter: "",
      isRead: false,
      attachmentName:
        attachmentsData.attachmentName +
          semiColon +
          attachmentString.attachmentName || "",
      attachmentUrl:
        attachmentsData.attachmentUrl +
          semiColon +
          attachmentString.attachmentUrl || "",
      sizeInKB: 0,
      subject: values && values.subject ? values.subject : "",
      from: selectedBranchName,
      import: false,
      to: to && to,
      cc: isSendCopy ? cc : ccc,
      bcc: bcc && bcc,
      notClients: false,
      clientReply: false,
      clientReplyMail: 0,
      importMessageId: "",
      draft: false,
      emailType: 0,
      importText: "",
      employerId: userDataEmp && userDataEmp.id,
      importedDate: new Date(),
      autoImport: false,
      isSent: true,
      clientEmailType: 0,
      timeStamp: new Date(),
    };

    onAddEmployerEmail(draftData)
      .then((resp) => {
        formData.append("emailId", resp.payload);
        const VisaOpt = {
          url: `v1/emailqueue/EmailQueueWithBlobUrl`,
        };
        VisaOpt.types = [
          "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
          "EMAIL_QUEUE_WITH_BLOB_FAILURE",
        ];

        apiRefresh
          .post(VisaOpt, formData)
          .then((res) => {
            changeState();

            if (emailContent && emailContent.draft) {
              let remove = {
                id: emailContent.id,
              };
              onDeleteCreateEmail(remove).then((res) => {
                onGetEmployerHistory(
                  userDataEmp.id,
                  "00000000-0000-0000-0000-000000000000",
                  "0",
                  "10",
                  "1",
                  "0"
                ).then((res) => {});

                setLoading(false);
                message.success("Successfully Sent!");
                setHandleKey("1");
              });
            } else {
              onGetEmployerHistory(
                userDataEmp.id,
                "00000000-0000-0000-0000-000000000000",
                "0",
                "10",
                "1",
                "0"
              ).then((res) => {});

              setLoading(false);
              message.success("Successfully Sent!");
              setHandleKey("1");
            }
            if (forward || reply || replyAll || isDraft) {
              handleCancelPopup();
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const changeState = () => {
    let signature = signatureRes ? signatureRes.signatureText : "";
    form.resetFields();
    setTo("");
    setCcc("");
    setBcc("");
    setSubject("");
    setLetterString(letterString + signature);
    setShowAttachmentsData([]);
    setContractValue("");
    setTemplateValue("");
    setAttachmentsData({
      attachmentName: "",
      attachmentUrl: "",
    });
    SetEmailFile([]);
    setContractsData([]);
    setFileList([]);
    setSelectedContracts([]);
  };

  const DraftData = () => {
    setLoading(true);
    var attachmentName = "";
    var attachmentUrl = "";
    var countContractIndex = 0;
    var contractAttachments = [];
    let attachmentString = {
      attachmentName: "",
      attachmentUrl: "",
    };
    var isContractAttachment = false;

    if (to && to.includes(","))  {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    if (ccc && ccc.includes(","))  {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    if (bcc && bcc.includes(","))  {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    var contentData = showEditor && letterString ? letterString + "<br/>" : "";
    // if (contractsData.length > 0) {
    //   for (var i = contractsData.length - 1; i > -1; i--) {
    //     if (!contractsData[i].isAttachment) {
    //       contentData += contractsData[i].content + "<br/>";
    //       countContractIndex++;
    //       if (
    //         !isContractAttachment &&
    //         countContractIndex == contractsData.length
    //       ) {
    //         onSaveData(contentData, attachmentString);
    //       }
    //     } else {
    //       isContractAttachment = true;
    //       var content = contractsData[i].content;
    //       var formValue = new FormData();
    //       formValue.append(`Html`, content);
    //       formValue.append(
    //         `BranchId`,
    //         localStorage.getItem("selectedBranchId")
    //       );

    //       formValue.append(`FileTitle`, contractsData[i].name + ".pdf");
    //       onGetPdf(formValue)
    //         .then((res) => {
    //           countContractIndex++;
    //           setLoading(false);
    //           let pdfData = {
    //             url: res.payload.uri,
    //             name: res.payload.fileName,
    //             type: "application/pdf",
    //           };
    //           contractAttachments.push(pdfData);
    //           // setShowAttachmentsData((prevState) => [...prevState, pdfData]);
    //           let semiColon = "";
    //           if (attachmentName !== "") {
    //             semiColon = ";";
    //           }

    //           attachmentName += semiColon + res.payload.fileName;
    //           attachmentUrl += semiColon + res.payload.uri;

    //           attachmentString = {
    //             attachmentUrl: attachmentUrl,
    //             attachmentName: attachmentName,
    //           };

    //           if (countContractIndex == contractsData.length) {
    //             onSaveData(contentData, attachmentString);
    //           }
    //         })
    //         .catch((err) => {
    //           setLoading(false);
    //         });
    //     }
    //   }
    // } else {
    onSaveData(attachmentString);
    // }
  };

  const onSaveData = (attachmentString) => {
    let imapEmail = localStorage.getItem("imapEmail");
    let userName = localStorage.getItem("userName");
    // if (imapEmail) {
    //   useEmail = imapEmail;
    // } else {
    //   useEmail = "messaging.service@ezymigrate.net";
    // }

    setLoading(true);
    let selectedBranchName = localStorage.getItem("selectedBranchName");
    let userId = localStorage.getItem("userId");
    var semiColon = "";
    if (
      attachmentsData.attachmentName != "" &&
      attachmentString.attachmentName != ""
    ) {
      semiColon = ";";
    }

    var dContracts = "";
    if (contractsData.length > 0) {
      dContracts = JSON.stringify(contractsData);
    }

    const draftData = {
      id: 0,
      emailMessage: letterString || "",
      draftContract: dContracts,
      draftLetter: "",
      isRead: false,
      attachmentName:
        attachmentsData.attachmentName +
          semiColon +
          attachmentString.attachmentName || "",
      attachmentUrl:
        attachmentsData.attachmentUrl +
          semiColon +
          attachmentString.attachmentUrl || "",
      sizeInKB: 0,
      subject: subject ? subject : "",
      from: selectedBranchName,

      import: false,
      to: to && to,
      cc: ccc && ccc,
      bcc: bcc && bcc,
      notClients: false,
      clientReply: false,
      clientReplyMail: 0,
      cUserId: userId,
      importMessageId: "",
      draft: true,
      emailType: 0,
      importText: "",
      employerId: userDataEmp && userDataEmp.id,
      importedDate: new Date(),
      autoImport: false,
      isSent: false,
      clientEmailType: 0,
      timeStamp: new Date(),
    };

    let familyId = sessionStorage.getItem("familyId");

    onAddEmployerEmail(draftData)
      .then((res) => {
        if (emailContent && emailContent.draft) {
          let remove = {
            id: emailContent.id,
          };
          onDeleteCreateEmail(remove).then((res) => {
            onGetEmployerHistory(
              userDataEmp.id,
              "00000000-0000-0000-0000-000000000000",
              "0",
              "10",
              "1",
              "0"
            ).then((res) => {});

            setLoading(false);
            message.success("Successfully Sent!");
            setHandleKey("1");
          });
        } else {
          onGetEmployerHistory(
            userDataEmp.id,
            "00000000-0000-0000-0000-000000000000",
            "0",
            "10",
            "1",
            "0"
          ).then((res) => {});
        }
        changeState();

        if (forward || reply || replyAll || isDraft) {
          handleCancelPopup();
        }
        setLoading(false);
        message.success("Saved!");
        setHandleKey("1");
      })
      .catch((err) => {
        message.error("Failed!");
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return (
          <div>
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      render: (text, record) => {
        return (
          <div>
            <span>{moment(text).format("DD MMM YYYY")}</span>
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      //width: "100px",
      className: "docNameInput",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return (
          <Input
            className="docInput"
            placeholder={record && record.title}
            onChange={(e) => {}}
          />
        );
      },
    },

    {
      title: "Document",
      dataIndex: "title",
      className: "docNameTitle",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            <p style={{ color: "#1890ff", fontSize: "11px" }}>
              {`${record && record.title}.${record &&
                record.docuementExtension} `}
            </p>
            {/* <p style={{ color: "#1890ff", fontSize: "10px" }}>
              {`${yyyymmddFormate(record && record.createdDate)} | ${record &&
                record.sizeInKB / 1000} KB `}
            </p> */}
          </div>
        );
      },
    },
  ];

  const comDocColumns = [
    {
      title: "#",
      key: "index",
      // width: "100px",
      render: (text, record, index) => {
        return (
          <div>
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      render: (text, record) => {
        return (
          <div>
            <span>{moment(text).format("DD MMM YYYY")}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      //width: "100px",
      className: "docNameInput",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return <p style={{ color: "#1890ff", fontSize: "11px" }}>{text}</p>;
      },
    },
  ];

  const visaFormColumns = [
    {
      title: "#",
      key: "index",
      width: "15px",
      textAlign: "center",
      render: (text, record, index) => {
        return (
          <div>
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "100px",
      className: "docNameInput",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return <p style={{ color: "#1890ff", fontSize: "11px" }}>{text}</p>;
      },
    },
  ];

  const removeClientEmail = (index, id) => {
    // var removeArr = contractsData.splice(index, 1);
    let removeContract = [...contractsData];

    removeContract.splice(index, 1);

    setContractsData(removeContract);
    let removeContractId = selectedContracts.findIndex((obj) => obj.id == id);
    if (removeContractId != -1) {
      let removeId = [...selectedContracts];
      removeId.splice(removeContractId, 1);
      setSelectedContracts(removeId);
    }
  };

  const handleEditNote = (value, id) => {
    var contractIndex = contractsData.findIndex((obj) => obj.id == id);

    let contracts = [...contractsData];
    if (contractIndex > -1) {
      contracts[contractIndex].content = value;

      setContractsData(contracts);
    }
    // setLetterString(value);
  };

  const onRequiredChange = (e) => {
    setIsSendCopy(e.target.checked);
    console.log(`checked = ${e.target.checked}`);
  };

  const contractDataAttachment = (checked, data) => {
    let attachmentName = attachmentsData.attachmentName;
    let attachmentUrl = attachmentsData.attachmentUrl;
    console.log(`checked = ${checked}`);

    var contractIndex = contractsData.findIndex((obj) => obj.id == data.id);

    let contracts = [...contractsData];
    if (contractIndex > -1) {
      contracts[contractIndex].isAttachment = checked;
      setContractsData(contracts);
    }
  };
  const onAddAttachment = (checked, data) => {
    let attachmentName = attachmentsData.attachmentName;
    let attachmentUrl = attachmentsData.attachmentUrl;
    console.log(`checked = ${checked}`);

    var contractIndex = contractsData.findIndex((obj) => obj.id == data.id);

    let contracts = [...contractsData];
    if (contractIndex > -1) {
      contracts[contractIndex].isAttachment = checked;
      setContractsData(contracts);
    }
    var content =
      contractIndex > -1 ? contractsData[contractIndex].content : data.content;

    if (checked) {
      setLoading(true);
      var formData = new FormData();
      formData.append(`Html`, content);
      formData.append(`BranchId`, localStorage.getItem("selectedBranchId"));
      formData.append(`FileTitle`, "Agreement Contract.pdf");
      onGetPdf(formData)
        .then((res) => {
          setLoading(false);
          let pdfData = {
            url: res.payload.uri,
            name: res.payload.fileName,
            type: res.payload.contentType,
          };
          setShowAttachmentsData((prevState) => [...prevState, pdfData]);
          let semiColon = "";
          if (attachmentName !== "") {
            semiColon = ";";
          }
          attachmentName += semiColon + res.payload.fileName;
          attachmentUrl += semiColon + res.payload.uri;
          setAttachmentsData({
            attachmentUrl: attachmentUrl,
            attachmentName: attachmentName,
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    SetPreviewVisible(true);
    SetPreviewImage(file.url || file.preview);

    SetPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  console.log(
    "emailDocumentResemailDocumentResemailDocumentResemailDocumentRes",
    emailDocumentRes
  );

  const onChangeMailOptions = (value, email) => {
    if (value.target.value == "to") {
      let toEmailString = to;
      if (to) toEmailString = to + ";" + email.address;
      else toEmailString = email.address;

      setTo(toEmailString);

      // if (toEmail) {
      //   emailArray = toEmail.splice(";");
      // }
      // if (emailArray.length > 0) {
      //   for (var i = 0; i < emailArray.length; i++) {
      //     if (i == 0) {
      //       emailString = emailArray[i];
      //     } else {
      //       emailString += ";" + emailArray[i];
      //     }
      //   }
      //   setToEmail(emailString);
      // }
    } else if (value.target.value == "cc") {
      let ccEmailString = ccc;
      if (ccc) {
        ccEmailString += ";" + email.address;
      } else {
        ccEmailString = email.address;
      }
      setCcc(ccEmailString);
    } else {
      let bccEmailString = bcc;
      if (bcc) {
        bccEmailString += ";" + email.address;
      } else {
        bccEmailString = email.address;
      }
      setBcc(bccEmailString);
    }
  };

  const onChangeCheckbox = (value) => {
    setSelectedEmails(value);

    var checkboxValue = to;
    if (value.length < selectedEmails.length) {
      // for
    }
    if (value.length > 0) {
      if (checkboxValue !== "") {
        checkboxValue += ";" + value[value.length - 1];
      } else {
        checkboxValue = value[0];
      }
    }
    // form.setFieldsValue({
    //   to: checkboxValue,
    // });
    setTo(checkboxValue);
    // setTo((prevState) => ({
    //   ...prevState,
    //   to: value[0],
    // }));
  };

  const onOkButtn = () => {
    let emails = [];
    let ccEmails = [];
    let bccEmails = [];
    var checkUnique = [];
    var checkCcUnique = [];
    var checkBccUnique = [];
    var uniqueEmailsString = "";
    var uniqueCcEmailsString = "";
    var uniqueBccEmailsString = "";

    if (to) {
      emails = to.split(";");
    }
    var uniqueEmailsString = "";
    if (emails.length > 0) {
      checkUnique = [...new Set(emails)];

      if (checkUnique.length > 0) {
        for (var i = 0; i < checkUnique.length; i++) {
          if (i !== 0) {
            uniqueEmailsString += ";" + checkUnique[i];
          } else {
            uniqueEmailsString = checkUnique[i];
          }
        }
      }
    }
    if (ccc) {
      ccEmails = ccc.split(";");
    }

    if (ccEmails.length > 0) {
      checkCcUnique = [...new Set(ccEmails)];

      if (checkCcUnique.length > 0) {
        for (var i = 0; i < checkCcUnique.length; i++) {
          if (i !== 0) {
            uniqueCcEmailsString += ";" + checkCcUnique[i];
          } else {
            uniqueCcEmailsString = checkCcUnique[i];
          }
        }
      }
    }
    if (bcc) {
      bccEmails = bcc.split(";");
    }

    if (bccEmails.length > 0) {
      checkBccUnique = [...new Set(bccEmails)];

      if (checkBccUnique.length > 0) {
        for (var i = 0; i < checkBccUnique.length; i++) {
          if (i !== 0) {
            uniqueBccEmailsString += ";" + checkBccUnique[i];
          } else {
            uniqueBccEmailsString = checkBccUnique[i];
          }
        }
      }
    }
    form.setFieldsValue({
      to: uniqueEmailsString,
      ccc: uniqueCcEmailsString,
      bcc: uniqueBccEmailsString,
    });
    //   form.setFieldsValue({ to: uniqueEmailsString });
    setShowEmailPopup(false);
  };

  const handleCancelDocPopup = () => {
    setShowDocPopup(false);
  };

  const handleCancelEmailPopup = () => setShowEmailPopup(false);

  const rowSelection1 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys1(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const rowSelection2 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys2(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const rowSelection3 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys3(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const getFileSize = async (fileName) => {
    let _size = [...size];
    const histOpt = {
      url: `v1/document/GetDocumentSize/${fileName}`,
    };
    histOpt.types = [
      "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
      "EMAIL_QUEUE_WITH_BLOB_FAILURE",
    ];
    await apiRefresh.get(histOpt).then((resp) => {
      _size[0] = _size[0] + resp;
    });
    return _size[0];
  };

  const onOkClientDocuments = async () => {
    let data = null;
    let attachmentUrl = attachmentsData.attachmentUrl;
    let attachmentName = attachmentsData.attachmentName;
    if (selectedRowKeys1.length > 0) {
      for (var i = 0; i < selectedRowKeys1.length; i++) {
        data = {
          url: selectedRowKeys1[i].blobFileName,
          name:
            selectedRowKeys1[i].title +
            "." +
            selectedRowKeys1[i].docuementExtension,
          type: selectedRowKeys1[i].docuementExtension,
        };
        let _resultSize = await getFileSize(data.url);

        if (_resultSize <= 15000000) {
          setSize([_resultSize]);
          setShowAttachmentsData((prevState) => [...prevState, data]);
          let semiColon = "";
          if (attachmentName !== "") {
            semiColon = ";";
          }
          attachmentName +=
            semiColon +
            selectedRowKeys1[i].title +
            "." +
            selectedRowKeys1[i].docuementExtension;
          attachmentUrl += semiColon + selectedRowKeys1[i].blobFileName;
        } else {
          message.error("Max upload file size reached! Uploading is failed!");
          break;
        }
      }
      setAttachmentsData({
        attachmentUrl: attachmentUrl,
        attachmentName: attachmentName,
      });
    }
    setShowDocPopup(false);
  };

  const onOkCommonDocuments = async () => {
    let data = null;
    let attachmentUrl = attachmentsData.attachmentUrl;
    let attachmentName = attachmentsData.attachmentName;
    if (selectedRowKeys2.length > 0) {
      for (var i = 0; i < selectedRowKeys2.length; i++) {
        data = {
          url: selectedRowKeys2[i].blobFileName,
          name: selectedRowKeys2[i].name,
          type: selectedRowKeys2[i].extension,
        };
        let _resultSize = await getFileSize(data.url);

        if (_resultSize <= 15000000) {
          setSize([_resultSize]);
          setShowAttachmentsData((prevState) => [...prevState, data]);
          let semiColon = "";
          if (attachmentName !== "") {
            semiColon = ";";
          }
          attachmentName += semiColon + selectedRowKeys2[i].name;
          attachmentUrl += semiColon + selectedRowKeys2[i].blobFileName;
        } else {
          message.error("Max upload file size reached! Uploading is failed!");
          break;
        }
      }
      setAttachmentsData({
        attachmentUrl: attachmentUrl,
        attachmentName: attachmentName,
      });
    }
    setShowComDocPopup(false);
  };

  async function createFile(link) {
    let fileArray = link.split("/");

    let fName = fileArray[fileArray.length - 1].split(".");

    console.log(fName);

    var hostName = window.location.origin;

    let response = await fetch(hostName + link);
    let data = await response.blob();
    let metadata = {
      type: "application/pdf",
    };
    let file = new File(
      [data],
      fName[0] + "." + fName[fName.length - 1],
      metadata
    );
    return file;
    // ... do something with the file or return it
  }

  const beforeUpload = (file, fileList) => {
    // Access file content here and do something with it
    // console.log(file)

    // Prevent upload
    return false;
  };

  const onOkVisaForm = () => {
    let data = null;
    let formData = new FormData();
    let visaFile = [];
    let attachmentUrl = attachmentsData.attachmentUrl;
    let attachmentName = attachmentsData.attachmentName;
    if (selectedRowKeys3.length > 0) {
      for (var i = 0; i < selectedRowKeys3.length; i++) {
        createFile(selectedRowKeys3[i].link).then((res) => {
          visaFile.push(res);
          if (visaFile.length == selectedRowKeys3.length) {
            formData.append(
              "BranchId",
              localStorage.getItem("selectedBranchId")
            );
            var count = 0;
            let _size = [...size];
            for (var i = 0; i < visaFile.length; i++) {
              formData.append("files", visaFile[i]);
              _size[0] = _size[0] + visaFile[i].size;
            }

            if (_size[0] <= 15000000) {
              setSize(_size);
              onAddEmailDocument(formData)
                .then((res) => {
                  for (var i = 0; i < res.payload.length; i++) {
                    if (res.payload[i].contentType) {
                      let pdfData = {
                        url: res.payload[i].fileUrl,
                        name: res.payload[i].fileName,
                        type: res.payload[i].contentType,
                      };
                      setShowAttachmentsData((prevState) => [
                        ...prevState,
                        pdfData,
                      ]);
                      let semiColon = "";
                      if (attachmentName !== "") {
                        semiColon = ";";
                      }
                      attachmentName += semiColon + res.payload[i].fileName;
                      attachmentUrl += semiColon + res.payload[i].fileUrl;
                    } else {
                      count++;
                    }
                  }
                  setAttachmentsData({
                    attachmentUrl: attachmentUrl,
                    attachmentName: attachmentName,
                  });
                  SetEmailFile(res && res.payload);
                  setLoading(false);
                  handleCancel();
                  if (count > 0) {
                    var file = count > 1 ? " Files" : " File";
                    message.info(count + file + " failed to attach");
                  }
                  message.success("Successfully Added!");
                })
                .catch((err) => {
                  if (err.payload && err.payload.response == undefined) {
                    message.error(
                      "Max upload file size reached! Uploading is failed!"
                    );
                  }
                  setLoading(false);
                });
              setAttachmentsData({
                attachmentUrl: attachmentUrl,
                attachmentName: attachmentName,
              });
              setShowVisaFormPopup(false);
            } else {
              message.error(
                "Max upload file size reached! Uploading is failed!"
              );
            }
          }
        });
      }
    }
  };

  const handleCancelVisaFormPopup = () => {
    setShowVisaFormPopup(false);
  };

  const onGenerateLink = (item) => {
    // if(item.content) {

    // }
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let userId = localStorage.getItem("userId");
    var findContractIndex = contractsData.findIndex((obj) => obj.id == item.id);

    if (!item.content.includes("@ClientSignature")) {
      message.info("Client signature not found. Can not generate link!");
    } else {
      setLoading(true);
      let data = {
        id: 0,
        clientID: userDataEmp && userDataEmp.id,
        contractHTML: item.content,
        contractSentDate: new Date(),
        isSigned: false,
        contractSignedDate: "1900-01-01T00:00:00.000Z",
        contractPdfUrl: "",
        isSent: true,
        sentBy: 0,
        branchID: selectedBranchId,
        header: item.header,
        city: "",
        countyCode: "",
        country: "",
        region: "",
        latitude: "",
        longitude: "",
        timezone: "",
        zip: "",
        ipAddress: "",
        oldId: "",
        countryOld: "",
        sentById: userId,
      };
      const contractOpt = {
        url: `v1/client/contract`,
      };
      contractOpt.types = ["GENAREATE_LINK_SUCCESS", "GENAREATE_LINK_FAILURE"];

      apiRefresh
        .post(contractOpt, data)
        .then((res) => {
          const VisaOpt = {
            url: `v1/client/contract/GetLink/${res}`,
          };
          VisaOpt.types = ["GET_LINK_SUCCESS", "GET_LINK_FAILURE"];

          let contracts = [...contractsData];
          apiRefresh
            .get(VisaOpt)
            .then((resp) => {
              setLoading(false);

              console.log(window.location);
              var http =
                window.location.host == "localhost:3000"
                  ? "http://"
                  : "https://";
              contracts[findContractIndex].hostString =
                "Copy the link in the email to send this contract, contract should have signature key (@ClientSignature) as the link purpose is to get the documents signed.";
              contracts[findContractIndex].host =
                http +
                window.location.host +
                `/AgreementBuilder/index.htm?param=${resp}`;

              setContractsData(contracts);

              // setKeys(resp.items);
            })
            .catch((err) => {
              setLoading(false);
            });
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const onClickPreview = (item) => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    var formData = new FormData();
    formData.append(`Html`, item.content);
    formData.append(`FileTitle`, "AgreementContract");
    formData.append(`BranchId`, selectedBranchId);
    const docTypeOpt = {
      url: `v1/config/GetPdf`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh.post(docTypeOpt, formData).then((res) => {
      const docViewOpt = {
        url: `v1/document/GetDocumentBytesforAttachment/${res.uri}/${selectedBranchId}`,
      };

      docViewOpt.types = [
        types.GET_DOCUMENT_PREVIEW_SUCCESS,
        types.GET_DOCUMENT_PREVIEW_FAILURE,
      ];
      apiRefresh.getFile(docViewOpt).then((response) => {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      });
    });

    // var findContractIndex = contractsData.findIndex((obj) => obj.id == item.id);
    // setLoading(true);
    // let data = {
    //   id: 0,
    //   clientID: clientprofileid,
    //   contractHTML: item.content,
    //   contractSentDate: "2021-10-07T16:00:59.446Z",
    //   isSigned: false,
    //   contractSignedDate: "2021-10-07T16:00:59.446Z",
    //   contractPdfUrl: "",
    //   isSent: true,
    //   sentBy: 0,
    //   branchID: selectedBranchId,
    //   header: item.header,
    //   city: "",
    //   countyCode: "",
    //   country: "",
    //   region: "",
    //   latitude: "",
    //   longitude: "",
    //   timezone: "",
    //   zip: "",
    //   ipAddress: "",
    //   oldId: "",
    //   countryOld: "",
    //   sentById: userId,
    // };
    // onGetLink(data).then((res) => {
    //   const VisaOpt = {
    //     url: `v1/client/contract/GetLink/${res.payload}`,
    //   };
    //   VisaOpt.types = ["GET_LINK_SUCCESS", "GET_LINK_FAILURE"];

    //   let contracts = [...contractsData];
    //   // apiRefresh.get(VisaOpt).then((resp) => {
    //   setLoading(false);

    //   console.log(window.location);
    //   // var http =
    //   //   window.location.host == "localhost:3000" ? "http://" : "https://";
    //   // var host =
    //   //   http +
    //   //   window.location.host +
    //   //   `/AgreementBuilder/index.htm?param=${res}`;

    //   // window.open(host + "&preview=1");

    //   // setContractsData(contracts);

    //   // setKeys(resp.items);
    //   // });
    // });
  };

  const handleCancelComDocPopup = () => {
    setShowComDocPopup(false);
  };

  const getFileSizeSimple = async (filename) => {
    let sizeFile;
    const histOpt = {
      url: `v1/document/GetDocumentSize/${filename}`,
    };
    histOpt.types = [
      "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
      "EMAIL_QUEUE_WITH_BLOB_FAILURE",
    ];
    await apiRefresh.get(histOpt).then((resp) => {
      sizeFile = resp;
    });
    return sizeFile;
  };

  const removeAttachment = async (attachment, index) => {
    let _resultSize = await getFileSizeSimple(attachment.url);

    let _size = [...size];

    setSize([_size[0] - _resultSize]);
    var attachmentsArray = [...showAttachmentsData];
    attachmentsArray.splice(index, 1);
    setShowAttachmentsData(attachmentsArray);
    let attachmentName = "";
    let attachmentUrl = attachment.url;
    let attachmentNameString = attachmentsData.attachmentName.split(";");
    let attachmentUrlString = attachmentsData.attachmentUrl.split(";");

    let findAttanchmentIndex = attachmentNameString.findIndex(
      (obj) => obj == attachment.name
    );
    if (findAttanchmentIndex > -1) {
      attachmentNameString.splice(findAttanchmentIndex, 1);
    }
    let findUrlIndex = attachmentUrlString.findIndex(
      (obj) => obj == attachment.name
    );
    if (findUrlIndex > -1) {
      attachmentUrlString.splice(findUrlIndex, 1);
    }
    if (attachmentNameString.length > 0) {
      let name = "";
      let url = "";
      for (var i = 0; i < attachmentNameString.length; i++) {
        if (i == 0) {
          name = attachmentNameString[i];
        } else {
          name += ";" + attachmentNameString[i];
        }
      }
      for (var i = 0; i < attachmentUrlString.length; i++) {
        if (i == 0) {
          url = attachmentUrlString[i];
        } else {
          url += ";" + attachmentUrlString[i];
        }
      }
      setAttachmentsData({
        attachmentName: name,
        attachmentUrl: url,
      });
    } else {
      setAttachmentsData({
        attachmentName: "",
        attachmentUrl: "",
      });
    }
  };

  const onChangeCcDropdown = (val) => {
    let cc = "";
    if (ccc) {
      cc = ccc + ";" + val;
      setCcc(cc);
      form.setFieldsValue({
        ccc: cc,
        to: to,
        bcc: bcc,
      });
    } else {
      setCcc(val);
      form.setFieldsValue({
        ccc: val,
        to: to,
        bcc: bcc,
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form onFinish={onFinish} form={form} className="width-100" name="main">
          <div className="border-box-checklist add-employer-para">
            <Row gutter={8}>
              <Col>
                <Form.Item required={false}>
                  <Select
                    showSearch
                    style={{ width: 250 }}
                    placeholder="CONTRACTS"
                    optionFilterProp="children"
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                    value={contractValue}
                    onChange={(val) => onContractChange(val, "contract")}
                  >
                    <Option key="">Contracts</Option>
                    {dataSource &&
                      dataSource.map((item) => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item required={false}>
                  <Select
                    showSearch
                    style={{ width: 250 }}
                    placeholder="LETTERS"
                    optionFilterProp="children"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                    value={templateValue}
                    onChange={(val) => onContractChange(val, "letter")}
                  >
                    <Option key="">Letters</Option>
                    {lettersData &&
                      lettersData.map((item) => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Modal
              visible={showEmailPopup}
              title={"Important Emails"}
              onCancel={handleCancelEmailPopup}
              onOk={onOkButtn}
              width="800px"
            >
              <>
                {/* <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={onChangeCheckbox}
                > */}
                {emailsList.length > 0 &&
                  emailsList.map((email, ind) => {
                    return (
                      <>
                        {email.address && (
                          <div className="imp-emails-row">
                            {/* <Checkbox value={email.address} /> */}
                            <div style={{ width: 200 }}>
                              <span>
                                {email.name +
                                  " " +
                                  email.memberType.toLowerCase() ==
                                "client"
                                  ? email.name + " (Applicant)"
                                  : email.memberType.toLowerCase() == "spouce"
                                  ? email.name + " (Partner)"
                                  : email.name + " (" + email.memberType + ")"}
                              </span>
                            </div>
                            <div style={{ width: 250 }}>
                              <span>{email.address}</span>
                            </div>
                            <Radio.Group
                              onChange={(e) => onChangeMailOptions(e, email)}
                              // defaultValue="to"
                            >
                              <Radio value="to">To</Radio>
                              <Radio value="cc">CC</Radio>
                              <Radio value="bcc">BCC</Radio>
                            </Radio.Group>
                          </div>
                        )}
                      </>
                    );
                  })}
                {/* </Checkbox.Group> */}
              </>
            </Modal>
            {/* <div>
              <div
                className="email-address-btn"
                onClick={() => setShowEmailPopup(true)}
              >
                <span>Email Addresses</span>
              </div>
            </div> */}
            <div className="create-email-form" style={{ width: "90%" }}>
              <Form.Item required={false}>
                <Checkbox onChange={onRequiredChange} checked={isSendCopy}>
                  Send me a copy
                </Checkbox>
              </Form.Item>
              <Form.Item name="to" rules={[{ required: true }]}>
                <Input
                  placeholder="To"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="ccc" required={false}>
                <Input
                  placeholder="CC"
                  value={ccc}
                  onChange={(e) => setCcc(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="bcc" required={false}>
                <Select
                  showSearch
                  style={{ width: 70 }}
                  placeholder="CC"
                  optionFilterProp="children"
                  onChange={onChangeCcDropdown}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  // filterOption={(input, option) =>
                  //   option.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  dropdownClassName="dropdown-options-width"
                >
                  {branchEmailAddresses.map((branchEmail, index) => (
                    <Option value={branchEmail.userEmail}>
                      {branchEmail.userEmail}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="bcc" required={false}>
                <Input
                  placeholder="Bcc"
                  value={bcc}
                  onChange={(e) => setBcc(e.target.value)}
                />
              </Form.Item>
              <Form.Item 
              name="subject"
              rules={[
                { required: true },
                {
                  max: 280,
                  message: "Subject should be less than 280 character",
                }
                ]}>
                <Input
                  placeholder="Subject"
                  maxLength={280}  // Set the character limit to 280
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Item>
            </div>
            {contractsData &&
              contractsData.map((data, index) => {
                return (
                  <Fragment>
                    <div style={{ margin: 15 }}>
                      <div>
                        <span style={{ fontSize: 12 }}>{data.hostString}</span>
                      </div>
                      <div style={{ marginTop: 10, display: "flex" }}>
                        <a href={data.host} target="_blank">
                          <span style={{ fontSize: 12 }}>{data.host}</span>
                        </a>
                        {data.host && (
                          <div style={{ marginLeft: 20, cursor: "pointer" }}>
                            {/* <CopyOutlined
                              onClick={() => {
                                navigator.clipboard.writeText(data.host);
                                message.info("Copied!");
                              }}
                            /> */}
                            <Button
                              style={{
                                fontSize: 10,
                                fontWeight: "500",
                                padding: 5,
                                borderRadius: 5,
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(data.host);
                                message.info("Copied!");
                              }}
                            >
                              Copy Link
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex width-100">
                      <div className="client-ehead d-flex space-between align-center">
                        <div>
                          <Form.Item required={false}>
                            <Checkbox
                              onChange={(e) =>
                                contractDataAttachment(e.target.checked, data)
                              }
                              checked={data.isAttachment}
                            >
                              Send as an attachment
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <div>
                          <Button
                            type="primary"
                            className="login-form-button save-btn button-blue"
                            onClick={() => onGenerateLink(data)}
                          >
                            Generate Contract Link
                          </Button>
                        </div>
                      </div>
                      <div className="client-ehead d-flex space-between align-center">
                        <div>
                          <Form.Item required={false}>
                            <Button
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={() => onClickPreview(data)}
                            >
                              Preview (for attachment only)
                            </Button>
                          </Form.Item>
                        </div>
                        {(showEditor || contractsData.length > 1) && (
                          <div className="close-editor">
                            <CloseCircleOutlined
                              style={{
                                color: "white",
                                backgroundColor: "#6C6666",
                                borderRadius: "44px",
                                // width: "30px",
                                fontSize: "20px",
                                marginRight: "8px",
                              }}
                              onClick={() => removeClientEmail(index, data.id)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "11px" }}
                      className="  letter-froala"
                    >
                      <CreateEmailEditors
                        data={data}
                        handleEditNote={handleEditNote}
                      />
                    </div>
                  </Fragment>
                );
              })}
            {contractsData.length > 0 && showEditor && (
              <div className="close-editor">
                <CloseCircleOutlined
                  style={{
                    color: "white",
                    backgroundColor: "#6C6666",
                    borderRadius: "44px",
                    // width: "30px",
                    fontSize: "20px",
                    marginRight: "8px",
                  }}
                  onClick={() => setShowEditor(false)}
                />
              </div>
            )}
            {showEditor && (
              <div className="margin-top-20 letter-froala">
                <FroalaEditorCom
                  setLoading={(value) => setLoading(value)}
                  model={letterString}
                  onModelChange={(value) => setLetterString(value)}
                />
              </div>
            )}

            <div className="margin-top-20">
              <Row>
                <Col span={24}>
                  <div className="ts-upload-file-cont">
                    <Form.Item name="username">
                      <Upload
                        multiple
                        style={{ backgroundColor: "#fff" }}
                        listType="picture-card"
                        fileList={[]}
                        beforeUpload={beforeUpload}
                        onPreview={handlePreview}
                        onChange={onHandleChange}
                        className={"upload-technical-support"}
                      >
                        {fileList.length < 10 && (
                          <div className="import-file-button-sec">
                            <div
                              onClick={() => {
                                setCounter(1);
                              }}
                            >
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                    {/* <Button
              // onClick={handleSubmit}
              className="float-right"
              htmlType="submit"
              type="primary"
            >
              Upload
            </Button> */}

                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Modal
            visible={showDocPopup}
            title={"Client Documents"}
            onCancel={handleCancelDocPopup}
            onOk={onOkClientDocuments}
            width="600px"
          >
            <>
              <Table
                className="border-3"
                rowSelection={rowSelection1}
                showCount={true}
                columns={columns}
                dataSource={docArray}
                pagination={false}
              />
            </>
          </Modal>
          <Modal
            visible={showComDocPopup}
            title={"Common Documents"}
            onCancel={handleCancelComDocPopup}
            onOk={onOkCommonDocuments}
            width="600px"
          >
            <>
              <Table
                className="border-3"
                rowSelection={rowSelection2}
                showCount={true}
                columns={comDocColumns}
                dataSource={commonDocArray}
                pagination={false}
              />
            </>
          </Modal>
          <Modal
            visible={showVisaFormPopup}
            title={"Visa Form"}
            onCancel={handleCancelVisaFormPopup}
            onOk={onOkVisaForm}
            width="600px"
          >
            <>
              <Table
                className="border-3"
                rowSelection={rowSelection3}
                showCount={true}
                columns={visaFormColumns}
                dataSource={visaFormArray}
                pagination={false}
              />
            </>
          </Modal>
          <div>
            <p className="label">Attachment(S)</p>
            <Row className="margin-top-12" gutter={10}>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    className="login-form-button save-btn button-blue"
                    onClick={() => setShowDocPopup(true)}
                  >
                    Client Documents
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    // onClick={() => DraftData()}
                    type="primary"
                    className="login-form-button save-btn button-blue"
                    onClick={() => setShowComDocPopup(true)}
                  >
                    Common Documents
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    // onClick={() => DraftData()}
                    type="primary"
                    className="login-form-button save-btn button-blue"
                    onClick={() => setShowVisaFormPopup(true)}
                  >
                    Visa Form
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <div className="attachments-container">
              {showAttachmentsData.length > 0 &&
                showAttachmentsData.map((attachment, ind) => (
                  <div className="attachment-content-item">
                    <img
                      src={Images.crossWhite}
                      style={{ width: 10, marginRight: 10, cursor: "pointer" }}
                      onClick={() => removeAttachment(attachment, ind)}
                    />
                    <span className="attachment-content-text">
                      {attachment.name}
                    </span>
                  </div>
                ))}
            </div>
            <Row>
              <div></div>
            </Row>
          </div>
          <Row
            className="margin-top-12"
            gutter={10}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  className="login-form-button save-btn button-blue"
                  htmlType="submit"
                >
                  Send Now
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  onClick={() => DraftData()}
                  type="primary"
                  className="login-form-button save-btn button-blue"
                >
                  Save As Draft
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
};
export default DocumentChecklistForm;
